import { useParams, useHistory } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { useEffect, useCallback, useState } from "react";
import Button from "../UI/Button";

const ApplyToAdopt = (props) => {
  const params = useParams();

  const history = useHistory();

  const [animalDets, setAnimalDets] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // This is where we get the details of the animal (like their name)
  const fetchAnimalDetailsHandler = useCallback(async (animalID) => {
    // console.log(`Fetching Details Of Animal ${animalID}...`);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="$key"&equalTo="' +
          animalID +
          '"'
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving animal details!"
        );
      }

      const data = await response.json();

      // console.log("data=", data);

      let animalDetails = {};

      for (const key in data) {
        animalDetails = {
          animalID: key,
          animalName: data[key].animalName,
          dateListed: data[key].dateListed,
          status: data[key].status,
          dateAdopted: data[key].dateAdopted,
          breed: data[key].breed,
          age: data[key].age,
          rescueID: data[key].rescueID,
          imageURL: data[key].imageURL,
        };
      }

      // console.log("animalDetails=", animalDetails);
      // console.log("animalDetails.animalID", animalDetails.animalID);

      console.log("params.animalID=", params.animalID);
      if (animalDetails.animalID || params.animalID.substring(0, 3) === "ReS") {
        console.log("Animal Found");
        if (animalDetails.status === "Adopted") {
          setError(
            "Animal Has Already Been Adopted.  Thanks for your interest."
          );
        } else {
          // If the animalID starts with "ReS" then the applicant is not a submitting an application
          // to adopt any particular animal.  They are just submitting an application to the rescue.
          if (params.animalID.substring(0, 3) === "ReS") {
            setAnimalDets({
              animalID: params.animalID,
              animalName: "",
              dateListed: "",
              status: "",
              dateAdopted: "",
              breed: "",
              age: "",
              rescueID: params.animalID.substring(3),
              imageURL: "",
            });
          } else {
            setAnimalDets(animalDetails);
          }
        }
      } else {
        setError("Animal Not Found!!");
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchAnimalDetailsHandler(params.animalID);
  }, [fetchAnimalDetailsHandler]);

  function onSignInHandler() {
    history.replace(
      "/login/apply-to-adopt/" + animalDets.rescueID + "/" + animalDets.animalID
    );
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%" }}></td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "7vh",
                    fontSize: "4vh",
                    fontWeight: "bold",
                  }}
                >
                  Plenty Of Pooches
                </td>
                <td style={{ width: "20%" }}></td>
              </tr>
            </tbody>
          </table>
        </div>
        {params.animalID.substring(0, 3) !== "ReS" && (
          <h1 style={{ textAlign: "center" }}>
            In order to apply to adopt {animalDets.animalName}, click the button
            below to sign in or to create a new account.
          </h1>
        )}
        {params.animalID.substring(0, 3) === "ReS" && (
          <h1 style={{ textAlign: "center" }}>
            In order fill out our application form, click the button below to
            sign in or to create a new account. Someone at the rescue will then
            contact you when they have an animal that would be a good fit for
            you.
          </h1>
        )}
        <div style={{ textAlign: "center" }}>
          <img src={animalDets.imageURL} style={{ maxHeight: "40vh" }} />
        </div>
        <br></br>
        <div style={{ textAlign: "center" }}>
          <Button onClick={onSignInHandler}>Sign In / Sign Up</Button>
        </div>
        <h3 style={{ textAlign: "center" }}>
          Plenty Of Pooches is a free application designed to simplify and
          automate the job of rescuing animals.
        </h3>
        <h3 style={{ textAlign: "center" }}>
          Your e-mail address and phone number will never be shared with anyone
          other than the rescue organizations of the animals for which you put
          in an application to adopt.
        </h3>
        <br></br>
      </>
    );
  }
};

export default ApplyToAdopt;
