import { useParams, useHistory } from "react-router-dom";
import { useEffect, useCallback, useState, useContext, useRef } from "react";
import LoadingSpinner from "./LoadingSpinner";
import AuthContext from "../store/auth-context";
import Button from "../UI/Button";

function ViewApplication() {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [forceReload, setForcReload] = useState(true);

  const [genericApplicationQuestions, setGenericApplicationQuestions] =
    useState([]);
  const [rescuesApplicationQuestions, setRescuesApplicationQuestions] =
    useState([]);
  const [animalApplicationQuestions, setAnimalApplicationQuestions] = useState(
    []
  );

  const [useStandardApplication, setUseStandardApplication] = useState(false);

  const genericInputRef = useRef([]);
  const rescueInputRef = useRef([]);
  const animalInputRef = useRef([]);

  const nonStateGenericApplicationQuestions = useRef([]);
  const nonStateRescueApplicationQuestions = useRef([]);
  const nonStateAnimalApplicationQuestions = useRef([]);

  const [applicant, setApplicant] = useState("");

  const [animalName, setAnimalName] = useState("");

  const rescueDetails = useRef({
    rescueName: "",
    rescueEmail: "",
  });

  function compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  // This is where we get the details of the animal (like their name)
  const fetchRescueDetails = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues.json?orderBy="$key"&equalTo="' +
          params.rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving rescue's details!"
        );
      }

      const data = await response.json();

      // console.log("fetchRescueDetails - data=", data);

      for (const key in data) {
        rescueDetails.current = {
          rescueName: data[key].name,
          rescueEmail: data[key].email,
        };
        setUseStandardApplication(data[key].usestandardapplication);
      }

      fetchApplicantDetailsHandler();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch the details of this applicant
  const fetchApplicantDetailsHandler = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant details!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      let applicantTemp = {};

      for (const key in data) {
        applicantTemp = {
          key: key,
          address: data[key].address,
          name: data[key].name,
          phone: data[key].phone,
          email: data[key].email,
        };
      }

      // console.log("applicantTemp = ", applicantTemp);

      setApplicant(applicantTemp);

      // Now fetch the name of the animal
      fetchAnimalNameHandler();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch animal's name to display
  const fetchAnimalNameHandler = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="$key"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error("Something went wrong while retrieving animal's name!");
      }

      const data = await response.json();

      // console.log(">> fetchAnimalNameHandler - data=", data);

      for (const key in data) {
        setAnimalName(data[key].animalName);
      }

      // Now call the function that will get the generic application questions.
      fetchGenericApplicationQuestions();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the list of generic applicaton questions.
  const fetchGenericApplicationQuestions = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/generic_application.json?&auth=" +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of standard application questions!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const applicationQuestions = [];

      for (const key in data) {
        applicationQuestions.push({
          questionID: key,
          field_name: data[key].field_name,
          required: data[key].required,
          type: data[key].type,
          highlight: false,
          response: "",
          responseID: "",
          deleted: data[key].deleted,
          order: data[key].order,
        });
      }

      applicationQuestions.sort(compare);

      // console.log("Standard Applicatin Questions", applicationQuestions);

      nonStateGenericApplicationQuestions.current = applicationQuestions;

      // Now call the function to get the rescue-specific questions.
      fetchRescuesApplicationQuestions();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the list of rescue's applicaton questions.
  const fetchRescuesApplicationQuestions = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues_application.json?orderBy="rescueID"&equalTo="' +
          params.rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of rescue application questions!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const applicationQuestions = [];

      for (const key in data) {
        applicationQuestions.push({
          questionID: key,
          field_name: data[key].field_name,
          required: data[key].required,
          type: data[key].type,
          highlight: false,
          response: "",
          responseID: "",
          deleted: data[key].deleted,
          order: data[key].order,
        });
      }

      applicationQuestions.sort(compare);

      // console.log(
      //   "Rescue-Specific Application Questions",
      //   applicationQuestions
      // );

      nonStateRescueApplicationQuestions.current = applicationQuestions;

      // Now call the function to get the animal-specific questions.
      fetchAnimalApplicationQuestions();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the list of animal's applicaton questions.
  const fetchAnimalApplicationQuestions = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals_application.json?orderBy="animalID"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of animal's application questions!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const applicationQuestions = [];

      for (const key in data) {
        applicationQuestions.push({
          questionID: key,
          field_name: data[key].field_name,
          required: data[key].required,
          type: data[key].type,
          highlight: false,
          response: "",
          responseID: "",
          deleted: data[key].deleted,
          order: data[key].order,
        });
      }

      applicationQuestions.sort(compare);

      // console.log(
      //   "Animal-Specific Application Questions",
      //   applicationQuestions
      // );

      nonStateAnimalApplicationQuestions.current = applicationQuestions;

      // Now call the function to get this applicants previous responses to the questions.
      fetchResponses();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the previous responses submitted by this applicant.
  const fetchResponses = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/responses.json?orderBy="applicantID"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of previous responses!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const responses = [];

      for (const key in data) {
        responses.push({
          responseID: key,
          animalID: data[key].animalID,
          applicantID: data[key].applicantID,
          field_name: data[key].field_name,
          questionID: data[key].questionID,
          rescueID: data[key].rescueID,
          response: data[key].response,
          type: data[key].type,
        });
      }

      // console.log("responses=", responses);

      // Now update the generic questions with the previous responses.
      nonStateGenericApplicationQuestions.current.forEach((e, idx) => {
        responses.forEach((r) => {
          if (e.questionID === r.questionID) {
            nonStateGenericApplicationQuestions.current[idx].response =
              r.response;
            nonStateGenericApplicationQuestions.current[idx].responseID =
              r.responseID;
          }
        });
      });

      // console.log(
      //   "nonStateGenericApplicationQuestions.current=",
      //   nonStateGenericApplicationQuestions.current
      // );

      setGenericApplicationQuestions(
        nonStateGenericApplicationQuestions.current
      );

      // Now update the rescue-specific questions with the previous responses.
      nonStateRescueApplicationQuestions.current.forEach((e, idx) => {
        responses.forEach((r) => {
          if (e.questionID === r.questionID) {
            nonStateRescueApplicationQuestions.current[idx].response =
              r.response;
            nonStateRescueApplicationQuestions.current[idx].responseID =
              r.responseID;
          }
        });
      });

      // console.log(
      //   "nonStateRescueApplicationQuestions.current=",
      //   nonStateRescueApplicationQuestions.current
      // );

      setRescuesApplicationQuestions(
        nonStateRescueApplicationQuestions.current
      );

      // Now update the animal-specific questions with the previous responses.
      nonStateAnimalApplicationQuestions.current.forEach((e, idx) => {
        responses.forEach((r) => {
          if (e.questionID === r.questionID) {
            nonStateAnimalApplicationQuestions.current[idx].response =
              r.response;
            nonStateAnimalApplicationQuestions.current[idx].responseID =
              r.responseID;
          }
        });
      });

      // console.log(
      //   "nonStateAnimalApplicationQuestions.current=",
      //   nonStateAnimalApplicationQuestions.current
      // );

      setAnimalApplicationQuestions(nonStateAnimalApplicationQuestions.current);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchRescueDetails();
  }, [fetchRescueDetails]);

  const genericQuestionsToShow = [];
  genericApplicationQuestions.forEach((q) => {
    if (q.deleted === false || q.response !== "") {
      genericQuestionsToShow.push(q);
    }
  });

  const rescuesQuestionsToShow = [];
  rescuesApplicationQuestions.forEach((q) => {
    if (q.deleted === false || q.response !== "") {
      rescuesQuestionsToShow.push(q);
    }
  });

  const animalQuestionsToShow = [];
  animalApplicationQuestions.forEach((q) => {
    if (q.deleted === false || q.response !== "") {
      animalQuestionsToShow.push(q);
    }
  });

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  function backToApplicantDetails() {
    history.push(
      "/applicant-details/" +
        params.applicantID +
        "/" +
        params.animalID +
        "/none"
    );
  }

  function cancelPage() {
    window.history.go(-1);
  }

  // console.log("useStandardApplication", useStandardApplication);
  console.log("authCtx.token=", authCtx.token);

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <button onClick={onLogout}>Login Again</button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%" }}>
                  {params.animalID !== "ALL" && (
                    <Button onClick={backToApplicantDetails}>
                      Back to Applicant Details
                    </Button>
                  )}
                  {params.animalID === "ALL" && (
                    <Button onClick={cancelPage}>Close</Button>
                  )}
                </td>
                <td style={{ width: "60%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "7vh",
                      fontSize: "4vh",
                      fontWeight: "bold",
                    }}
                  >
                    <label style={{ textDecoration: "underline" }}>
                      {applicant.name}
                    </label>
                    <label> Application Responses </label>
                  </div>
                </td>
                <td style={{ width: "20%" }}></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            height: "78vh",
            overflowY: "scroll",
            border: "1px solid black",
          }}
        >
          {useStandardApplication === true && (
            <>
              <h1
                style={{
                  textDecoration: "underline",
                  fontStyle: "italic",
                  paddingLeft: 20,
                }}
              >
                Standard Application Questions:
              </h1>
              {genericQuestionsToShow.map((question, idx) => (
                <div
                  key={question.questionID}
                  style={{
                    display: "flex",
                    padding: "10px",
                    border: "1px solid black",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 4,
                      borderRight: "1px solid black",
                    }}
                  >
                    <label style={{ fontWeight: "bold" }}>
                      {question.field_name}{" "}
                    </label>
                  </div>
                  <div style={{ flex: 8 }}>
                    {question.type === "Text" && (
                      <textarea
                        ref={(el) => (genericInputRef.current[idx] = el)}
                        // type={question.type === "Text" ? "text" : "number"}
                        defaultValue={question.response}
                        style={{
                          border: "none",
                          fontWeight: "bold",
                          width: "98%",
                          resize: "none",
                          marginLeft: "5px",
                          // marginRight: "5px",
                        }}
                        wrap="soft"
                        disabled
                      />
                    )}
                    {question.type === "Number" && (
                      <input
                        ref={(el) => (genericInputRef.current[idx] = el)}
                        type="number"
                        defaultValue={question.response}
                        style={{
                          border: "none",
                          // borderBottom: "1px solid black",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                        disabled
                      />
                    )}
                    {question.type === "Yes/No" && (
                      <select
                        type="text"
                        ref={(el) => (genericInputRef.current[idx] = el)}
                        defaultValue={question.response}
                        style={{
                          border: "none",
                          // borderBottom: "1px solid black",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                        disabled
                      >
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    )}
                  </div>
                  <div style={{ flex: 1 }}>
                    {question.required && (
                      <label
                        style={{
                          color: question.highlight ? "red" : "black",
                          paddingLeft: "10px",
                        }}
                      >
                        (required)
                      </label>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
          <br></br>
          <h1
            style={{
              textDecoration: "underline",
              fontStyle: "italic",
              paddingLeft: 20,
            }}
          >
            Rescue-specific Application Questions:
          </h1>
          {rescuesQuestionsToShow.map((question, idx) => (
            <div
              key={question.questionID}
              style={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div
                style={{
                  flex: 4,
                  borderRight: "1px solid black",
                }}
              >
                <label style={{ fontWeight: "bold" }}>
                  {question.field_name}{" "}
                </label>
              </div>
              <div style={{ flex: 8 }}>
                {question.type === "Text" && (
                  <textarea
                    ref={(el) => (rescueInputRef.current[idx] = el)}
                    // type={question.type === "Text" ? "text" : "number"}
                    defaultValue={question.response}
                    style={{
                      border: "none",
                      fontWeight: "bold",
                      width: "98%",
                      resize: "none",
                      marginLeft: "5px",
                      // marginRight: "5px",
                    }}
                    wrap="soft"
                    disabled
                  />
                )}
                {question.type === "Number" && (
                  <input
                    ref={(el) => (rescueInputRef.current[idx] = el)}
                    type="number"
                    defaultValue={question.response}
                    style={{
                      border: "none",
                      // borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                    disabled
                  />
                )}
                {question.type === "Yes/No" && (
                  <select
                    type="text"
                    ref={(el) => (rescueInputRef.current[idx] = el)}
                    defaultValue={question.response}
                    style={{
                      border: "none",
                      // borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                    disabled
                  >
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div style={{ flex: 1 }}>
                {question.required && (
                  <label
                    style={{
                      color: question.highlight ? "red" : "black",
                      paddingLeft: "10px",
                    }}
                  >
                    (required)
                  </label>
                )}
              </div>
            </div>
          ))}
          <br></br>
          {params.animalID !== "ALL" && (
            <>
              <h1
                style={{
                  textDecoration: "underline",
                  fontStyle: "italic",
                  paddingLeft: 20,
                }}
              >
                Animal-specific Application Questions for '{animalName}'
              </h1>
              {animalQuestionsToShow.map((question, idx) => (
                <div
                  key={question.questionID}
                  style={{
                    display: "flex",
                    padding: "10px",
                    border: "1px solid black",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 4,
                      borderRight: "1px solid black",
                    }}
                  >
                    <label style={{ fontWeight: "bold" }}>
                      {question.field_name}{" "}
                    </label>
                  </div>
                  <div style={{ flex: 8 }}>
                    {question.type === "Text" && (
                      <textarea
                        ref={(el) => (animalInputRef.current[idx] = el)}
                        // type={question.type === "Text" ? "text" : "number"}
                        defaultValue={question.response}
                        style={{
                          border: "none",
                          fontWeight: "bold",
                          width: "98%",
                          resize: "none",
                          marginLeft: "5px",
                          // marginRight: "5px",
                        }}
                        wrap="soft"
                        disabled
                      />
                    )}
                    {question.type === "Number" && (
                      <input
                        ref={(el) => (animalInputRef.current[idx] = el)}
                        type="number"
                        defaultValue={question.response}
                        style={{
                          border: "none",
                          // borderBottom: "1px solid black",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                        disabled
                      />
                    )}
                    {question.type === "Yes/No" && (
                      <select
                        type="text"
                        ref={(el) => (animalInputRef.current[idx] = el)}
                        defaultValue={question.response}
                        style={{
                          border: "none",
                          // borderBottom: "1px solid black",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                        disabled
                      >
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    )}
                  </div>
                  <div style={{ flex: 1 }}>
                    {question.required && (
                      <label
                        style={{
                          color: question.highlight ? "red" : "black",
                          paddingLeft: "10px",
                        }}
                      >
                        (required)
                      </label>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    );
  }
}

export default ViewApplication;
