import { useParams, useHistory } from "react-router-dom";
import React, {
  useRef,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import LoadingSpinner from "./LoadingSpinner";
import AuthContext from "../store/auth-context";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

import Button from "../UI/Button";

import upArrowPng from "../images/arrow-up.png";
import downArrowPng from "../images/arrow-down.png";

function UpdateRescueInfo(props) {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [appQuestions, setAppQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState("Text");
  const [questionReq, setQuestionReq] = useState("");

  const nameRef = useRef("");
  const phoneNumberRef = useRef("");

  const initialAppQuestions = useRef([]);

  const currentValues = useRef({
    rescueID: "",
    name: "",
    phoneNumber: "",
    imageURL: "",
  });

  function compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  // Function to get the details for this rescue.
  const fetchRescueInfo = useCallback(async (rescueID) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues.json?orderBy="$key"&equalTo="' +
          rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error("Something went wrong while retrieving rescue info!");
      }

      const data = await response.json();

      // console.log(">> data=", data);

      for (const key in data) {
        currentValues.current = {
          rescueID: key,
          name: data[key].name,
          phoneNumber: data[key].phoneNumber,
        };
        setImageUrl(data[key].imageURL);
      }

      // Now get list of application questions for this rescue.
      fetchRescueApplicationQuestions(rescueID);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the list of rescue's applicaton questions.
  const fetchRescueApplicationQuestions = useCallback(async (rescueID) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues_application.json?orderBy="rescueID"&equalTo="' +
          rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of rescue's application questions!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const applicationQuestions = [];

      for (const key in data) {
        if (data[key].deleted === false) {
          applicationQuestions.push({
            questionID: key,
            field_name: data[key].field_name,
            required: data[key].required,
            type: data[key].type,
            order: data[key].order,
          });
        }
      }

      applicationQuestions.sort(compare);

      console.log(
        "Rescue-Specific Application Questions",
        applicationQuestions
      );
      setAppQuestions(applicationQuestions);

      initialAppQuestions.current = [...applicationQuestions];
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (params.rescueID !== "none") {
      fetchRescueInfo(params.rescueID);
    }
  }, []);

  // This function submits all the data except the questions when adding an rescue
  const changeRescueHandler = useCallback(async (rescue, tempQuestions) => {
    // console.log("changeRescueHandler - tempQuestions=", tempQuestions);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues/" +
          params.rescueID +
          ".json?auth=" +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify(rescue),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing rescue to database!"
        );
      }

      const data = await response.json();

      const rescueID = params.rescueID;

      tempQuestions.forEach((e, indx) => {
        // console.log("Calling addQuestion for question ", e);
        if (e.questionID === "") {
          // User has added a new question
          addQuestionHandler(e, rescueID, indx);
        } else {
          // User has changed an existing question
          changeExistingQuestion(e, rescueID, e.questionID, indx);
        }
      });

      initialAppQuestions.current.forEach((e) => {
        let deleted = true;
        tempQuestions.forEach((q) => {
          if (q.questionID === e.questionID) {
            deleted = false;
          }
        });

        if (deleted) {
          console.log(`Question ${e.questionID} has been deleted`);
          deleteExistingQuestion(e.questionID);
        }
      });

      setTimeout(() => alert("Rescue as been succesfully updated!!"), 500);

      closePage();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This function changes an existing question.
  const changeExistingQuestion = useCallback(
    async (question, rescueID, questionID, order) => {
      // console.log("rescueID=", rescueID);
      // console.log("question=", question);
      // console.log("questionID", questionID);

      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues_application/" +
            questionID +
            ".json?auth=" +
            authCtx.token,
          {
            method: "PATCH",
            body: JSON.stringify({
              rescueID: rescueID,
              field_name: question.field_name,
              type: question.type,
              required: question.required,
              order: order,
              changedBy: authCtx.userID,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while writing rescue application questions to database!"
          );
        }

        const data = await response.json();
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    []
  );

  // This function changes an existing the questions.
  const deleteExistingQuestion = useCallback(async (questionID) => {
    // console.log("rescueID=", rescueID);
    // console.log("question=", question);
    console.log("deleteExistingQuestion - questionID", questionID);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues_application/" +
          questionID +
          ".json?auth=" +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify({
            deleted: true,
            changedBy: authCtx.userID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing rescue's application questions to database!"
        );
      }

      const data = await response.json();

      console.log("deleteExistingQuestion - data=", data);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This function submits the questions.
  const addQuestionHandler = useCallback(async (question, rescueID, order) => {
    console.log("rescueID=", rescueID);
    console.log("question=", question);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues_application.json?auth=" +
          authCtx.token,
        {
          method: "POST",
          body: JSON.stringify({
            rescueID: rescueID,
            field_name: question.field_name,
            type: question.type,
            required: question.required,
            deleted: false,
            order: order,
            changedBy: authCtx.userID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing rescue's application questions to database!"
        );
      }

      const data = await response.json();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This function submits all the data except the questions when changing an existing rescue.
  const addRescueHandler = useCallback(async (rescue, tempQuestions) => {
    console.log("addRescueHandler - tempQuestions=", tempQuestions);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues.json?auth=" +
          authCtx.token,
        {
          method: "POST",
          body: JSON.stringify(rescue),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing rescue to database!"
        );
      }

      const data = await response.json();

      console.log("Response from adding rescue =", data);

      const rescueID = data.name;

      tempQuestions.forEach((e) => {
        console.log("Calling addQuestion for question ", e);
        addQuestionHandler(e, rescueID);
      });

      setIsLoading(false);
      alert("Rescue as been succesfully added!");

      closePage();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function submitHandler(event) {
    // console.log("running submitHandler now...");
    const tempQuestions = [...appQuestions];
    // console.log("submitHandler - tempQuestions=", tempQuestions);

    event.preventDefault();

    if (nameRef.current.value === "" || phoneNumberRef.current.value === "") {
      alert(" Must fill in all the required fields.");
      return;
    }

    const rescue = {
      name: nameRef.current.value,
      phoneNumber: phoneNumberRef.current.value,
      imageURL: imageUrl,
      changedBy: authCtx.userID,
    };

    if (params.rescueID === "none") {
      addRescueHandler(rescue, tempQuestions);
    } else {
      changeRescueHandler(rescue, tempQuestions);
    }
  }

  const uploadImage = (event) => {
    //
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_) => {
      let files = Array.from(input.files);
      // console.log("files[0] = ", files[0]);
      // console.log("files[0].name =", files[0].name);

      setImageUpload(files[0]);
      const image2Upload = files[0];

      if (image2Upload == null) return;

      const imageRef = ref(storage, `images/${v4()}`);
      uploadBytes(imageRef, image2Upload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          // alert(`Image Uploaded URL= ${url}`);
          setImageUrl(url);
        });
      });
    };
    input.click();
  };

  function closePage() {
    // if (params.rescueID === "none") {
    //   history.replace("/rescue-dashboard/" + authCtx.userID);
    // } else {
    //   history.replace("/rescue/" + params.rescue);
    // }
    window.history.go(-1);
  }

  function addRescueApplicationQuestion() {
    if (question === "") {
      alert("Question cannot be blank");
    } else {
      let alreadyInList = false;
      appQuestions.forEach((e) => {
        if (e.field_name === question) {
          alreadyInList = true;
        }
      });
      if (alreadyInList) {
        alert("Cannot add the same question twice.");
      } else {
        setAppQuestions((prev) => {
          const tmpAppQuestions = [...prev];
          tmpAppQuestions.push({
            questionID: "",
            field_name: question,
            type: questionType,
            required: questionReq,
          });
          return [...tmpAppQuestions];
        });
        setQuestion("");
        setQuestionType("Text");
        setQuestionReq("");
      }
    }
  }

  function deleteRescueApplicationQuestion(position) {
    setAppQuestions((prev) => {
      const tmpAppQuestions = [...prev];
      tmpAppQuestions.splice(position, 1);
      return [...tmpAppQuestions];
    });
  }

  function moveQuestionUp(position) {
    // console.log(`Moving question in postion ${position} up`);
    let applicationQuestions = [];
    applicationQuestions = [...appQuestions];
    applicationQuestions[position].order =
      applicationQuestions[position].order - 1;
    applicationQuestions[position - 1].order =
      applicationQuestions[position - 1].order + 1;
    applicationQuestions.sort(compare);
    setAppQuestions(applicationQuestions);
  }

  function moveQuestionDown(position) {
    // console.log(`Moving question in postion ${position} up`);
    let applicationQuestions = [];
    applicationQuestions = [...appQuestions];
    applicationQuestions[position].order =
      applicationQuestions[position].order + 1;
    applicationQuestions[position + 1].order =
      applicationQuestions[position + 1].order - 1;
    applicationQuestions.sort(compare);
    setAppQuestions(applicationQuestions);
  }

  function questionEventHandler(event) {
    setQuestion(event.target.value);
  }

  function questionTypeEventHandler(event) {
    setQuestionType(event.target.value);
  }

  function questionReqEventHandler(event) {
    if (event.target.checked) {
      setQuestionReq(true);
    } else {
      setQuestionReq(false);
    }
  }

  // console.log("currentValues.current=", currentValues.current);
  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "20%" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button onClick={closePage}>CANCEL</Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Button onClick={submitHandler}>
                              {params.rescueID === "none"
                                ? "Add Rescue"
                                : "Update Rescue"}
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "7vh",
                      fontSize: "6vh",
                      fontWeight: "bold",
                    }}
                  >
                    Update Rescue Info.
                  </td>
                  <td style={{ width: "20%" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {imageUrl !== null && (
                              <img
                                src={imageUrl}
                                style={{ maxHeight: "18vh" }}
                              />
                            )}
                          </td>
                          <td>
                            <button type="button" onClick={uploadImage}>
                              Upload Image
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            marginTop: "2vh",
            // height: "10vh",
          }}
        >
          {isLoading && <LoadingSpinner />}
        </div>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "35%" }}></td>
              <td style={{ width: "15%" }}>
                <label
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                  htmlFor="name"
                >
                  Name
                </label>
              </td>
              <td style={{ width: "15%" }}>
                <input
                  type="text"
                  defaultValue={currentValues.current.name}
                  id="name"
                  ref={nameRef}
                ></input>
              </td>
              <td style={{ width: "35%" }}></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <label
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                  htmlFor="phonenumber"
                >
                  Phone Number
                </label>
              </td>
              <td>
                <input
                  type="tel"
                  defaultValue={currentValues.current.phoneNumber}
                  id="phonenumber"
                  ref={phoneNumberRef}
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <table style={{ width: "98vw" }}>
          <tbody>
            <tr>
              <td style={{ width: "4%" }}></td>
              <td style={{ width: "71%" }}>
                <label style={{ fontWeight: "bold" }}>New Question</label>
              </td>
              <td style={{ width: "10% " }}>
                <label style={{ fontWeight: "bold" }}>Type</label>
              </td>
              <td style={{ width: "10% " }}>
                <label style={{ fontWeight: "bold" }}>Required?</label>
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <input
                  style={{ width: "95%" }}
                  value={question}
                  onChange={questionEventHandler}
                />
              </td>
              <td>
                <select
                  value={questionType}
                  onChange={questionTypeEventHandler}
                  style={{ width: "90%" }}
                >
                  <option value="Text">Text</option>
                  <option value="Number">Number</option>
                  <option value="Yes/No">Yes/No</option>
                </select>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={questionReq ? "checked" : ""}
                  onChange={questionReqEventHandler}
                />
              </td>
              <td>
                <button onClick={addRescueApplicationQuestion}>+</button>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <table style={{ width: "98vw" }}>
          <tbody>
            <tr>
              <td style={{ width: "4%" }}></td>
              <td style={{ width: "71%", backgroundColor: "#38015c" }}>
                <label
                  style={{
                    color: "white",
                  }}
                >
                  Application Question
                </label>
              </td>
              <td style={{ width: "10%", backgroundColor: "#38015c" }}>
                <label style={{ color: "white" }}>Type</label>
              </td>
              <td style={{ width: "10%", backgroundColor: "#38015c" }}>
                <label style={{ color: "white" }}>Required?</label>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            height: "52vh",
            overflowY: "scroll",
            border: "1px solid black",
          }}
        >
          <table style={{ width: "98vw" }}>
            <tbody>
              {appQuestions.map((question, index) => (
                <tr key={question.field_name}>
                  <td style={{ width: "2%" }}>
                    {index !== 0 && (
                      <img
                        src={upArrowPng}
                        alt="UP"
                        style={{
                          height: "1em",
                          cursor: "pointer",
                        }}
                        onClick={moveQuestionUp.bind(this, index)}
                        title="Move Question Up"
                      />
                    )}
                  </td>
                  <td style={{ width: "2%" }}>
                    {index !== appQuestions.length - 1 && (
                      <img
                        src={downArrowPng}
                        alt="UP"
                        style={{
                          height: "1em",
                          cursor: "pointer",
                        }}
                        onClick={moveQuestionDown.bind(this, index)}
                        title="Move Question Down"
                      />
                    )}
                  </td>
                  <td style={{ width: "71%" }}>
                    <input
                      style={{ width: "95%" }}
                      defaultValue={question.field_name}
                      disabled
                    />
                  </td>
                  <td style={{ width: "10%" }}>
                    <input
                      defaultValue={question.type}
                      disabled
                      style={{ width: "90%" }}
                    />
                  </td>
                  <td style={{ width: "10%" }}>
                    <input
                      type="checkbox"
                      checked={question.required ? "checked" : ""}
                      disabled
                    />
                  </td>
                  <td>
                    <button
                      onClick={deleteRescueApplicationQuestion.bind(
                        this,
                        index
                      )}
                    >
                      -
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default UpdateRescueInfo;
