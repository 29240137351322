import { useParams, useHistory } from "react-router-dom";
import { useCallback, useEffect, useState, useContext, useRef } from "react";
import AuthContext from "../store/auth-context";
import LoadingSpinner from "./LoadingSpinner";
import NewMessageModal from "./NewMessageModal";

import Button from "../UI/Button";

function AppAnimalDetail() {
  const params = useParams();

  // console.log("GOT HERE - animalID=", params.animalID);

  const authCtx = useContext(AuthContext);

  // console.log("UserID=", authCtx.userID);
  // console.log("UserName=", authCtx.userName);

  const history = useHistory();

  const [animalDets, setAnimalDets] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [openNewMessageModal, setOpenNewMessageModal] = useState(false);

  const [applicant, setApplicant] = useState("");
  const [applicantMessages, setApplicantMessages] = useState([]);

  const [rescueDetails, setRescueDetails] = useState({});

  const animalsRescueID = useRef("");

  const tempMessages = useRef([]);

  const [applicationStatus, setApplicationStatus] = useState("");

  // This is where we get the details of the animal (like their name)
  const fetchAnimalDetailsHandler = useCallback(async (animalID) => {
    // console.log(`Fetching Details Of Animal ${animalID}...`);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="$key"&equalTo="' +
          params.animalID +
          '"'
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving animal details!"
        );
      }

      const data = await response.json();

      // console.log("data=", data);

      let animalDetails = {};

      for (const key in data) {
        animalDetails = {
          animalID: key,
          animalName: data[key].animalName,
          dateListed: data[key].dateListed,
          status: data[key].status,
          dateAdopted: data[key].dateAdopted,
          breed: data[key].breed,
          age: data[key].age,
          rescueID: data[key].rescueID,
          imageURL: data[key].imageURL,
        };
        animalsRescueID.current = data[key].rescueID;
      }

      // console.log("animalDetails=", animalDetails);
      // console.log("animalDetails.animalID", animalDetails.animalID);

      if (animalDetails.animalID) {
        // console.log("Animal Found");
        setAnimalDets(animalDetails);

        // Now fetch the message history for this applicant and animal
        fetchMessageHistory();
      } else {
        setError("Animal Not Found!!");
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This is where we get the details of the animal (like their name)
  const fetchRescueDetails = useCallback(async () => {
    // console.log(`Fetching Details Of rescue ${animalsRescueID.current}...`);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues.json?orderBy="$key"&equalTo="' +
          animalsRescueID.current +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving rescue's details!"
        );
      }

      const data = await response.json();

      // console.log("fetchRescueDetails - data=", data);

      for (const key in data) {
        setRescueDetails({
          rescueName: data[key].name,
          rescueEmail: data[key].email,
        });
      }

      // Now get the status of the application
      getApplicationId();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch the details of this applicant
  const fetchApplicantDetailsHandler = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant details!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      let applicantTemp = {};

      for (const key in data) {
        applicantTemp = {
          key: key,
          address: data[key].address,
          name: data[key].name,
          phone: data[key].phone,
        };
      }

      // console.log("applicantTemp = ", applicantTemp);

      setApplicant(applicantTemp);

      // Now get some infor on the rescue (like the name and main e-mail address)
      fetchRescueDetails();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch the messages for this this applicant regarding this animal
  const fetchMessageHistory = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/messages.json?orderBy="applicantID"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant messages!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      tempMessages.current = [];

      for (const key in data) {
        if (data[key].animalID === params.animalID) {
          const tmp = data[key].date.split("/");
          const intDate = tmp[2] + tmp[0] + tmp[1] + "_" + data[key].time;
          tempMessages.current.push({
            messageID: key,
            animalID: data[key].animalID,
            senderUserID: data[key].senderUserID,
            senderUserName: data[key].senderUserName,
            type: data[key].type,
            date: data[key].date,
            time: data[key].time,
            read: data[key].read,
            new: data[key].new,
            message: data[key].message,
            intDate: intDate,
            attachmentsArray: data[key].attachmentsArray
              ? data[key].attachmentsArray
              : [],
          });
        }
      }

      // console.log("tempMessages = ", tempMessages);

      setApplicantMessages(tempMessages.current);

      fetchApplicantDetailsHandler();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to loop through messages and set any unread messages to read in the database.
  function setUnreadToRead() {
    tempMessages.current.forEach((messageObj) => {
      if (
        messageObj.new === "Yes" &&
        messageObj.type === "From-Rescue-To-Applicant"
      ) {
        // Call the function to set this message to read
        updateMessageToRead(messageObj.messageID);
      }
    });
  }

  // Function to set the specified message to 'read'
  const updateMessageToRead = useCallback(async (messageID) => {
    console.log(`Setting message with ID ${messageID} to read.`);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/messages/" +
          messageID +
          ".json?" +
          '"&auth=' +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify({
            new: "No",
            changedBy: authCtx.userID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while marking messages as 'read'!"
        );
      }

      const data = await response.json();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchAnimalDetailsHandler(params.animalID);
  }, [fetchAnimalDetailsHandler]);

  function backToDashboard() {
    history.push("/applicant-dashboard/" + authCtx.userID);
  }

  function addMessageToList(messageObject) {
    console.log("running addMessageToList - messageObject=", messageObject);
    setApplicantMessages((prev) => {
      const tmp = messageObject.date.split("/");
      const intDate = tmp[2] + tmp[0] + tmp[1] + "_" + messageObject.time;
      const tmp1 = [
        ...prev,
        {
          messageID: intDate,
          animalID: messageObject.animalID,
          senderUserID: messageObject.senderUserID,
          senderUserName: messageObject.senderUserName,
          type: messageObject.type,
          date: messageObject.date,
          time: messageObject.time,
          read: messageObject.read,
          new: messageObject.new,
          message: messageObject.message,
          intDate: intDate,
          attachmentsArray: messageObject.attachmentsArray,
        },
      ];
      return tmp1;
    });
  }

  function compare(a, b) {
    if (a.intDate > b.intDate) {
      return -1;
    }
    if (a.intDate < b.intDate) {
      return 1;
    }
    return 0;
  }

  applicantMessages.sort(compare);

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  // This is where we download the file to the user's browser.
  const downloadAttachment = useCallback(async (url) => {
    console.log("Downloading attachment - url=", url);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Something went wrong while downloading the file!");
      }

      const data = await response.json();

      console.log("data=", data);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This is where we get the application status.
  const getApplicationId = useCallback(async () => {
    // console.log("Getting application current status...");

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applications.json?orderBy="animalID"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of applications!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      for (const key in data) {
        if (data[key].applicantID === params.applicantID) {
          setApplicationStatus(data[key].status);
        }
      }

      // Now call the function that will set all unread messages to read.
      setUnreadToRead();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // console.log("openMessageModal=", openNewMessageModal);

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button onClick={backToDashboard}>
                            Back to Dashboard
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button onClick={() => setOpenNewMessageModal(true)}>
                            Send New Message
                          </Button>
                        </td>
                      </tr>
                      {/* {params.special === "special" && (
                    <Button onClick={closeAppAnimalDetail}>Dashboard</Button>
                  )} */}
                    </tbody>
                  </table>
                </td>
                <td style={{ width: "60%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "7vh",
                      fontSize: "4vh",
                      fontWeight: "bold",
                    }}
                  >
                    {animalDets.animalName} ({rescueDetails.rescueName})
                  </div>
                </td>
                <td style={{ width: "20%" }}>
                  <img
                    src={animalDets.imageURL}
                    style={{ maxHeight: "18vh" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br></br>
        <table
          style={{
            width: "100%",
            // marginLeft: "25vw",
            marginTop: "3vw",
            // border: "1px solid red",
          }}
        >
          <tbody>
            <tr>
              <td style={{ width: "15%" }}>
                <label style={{ textDecoration: "underline" }}>Breed:</label>
              </td>
              <td style={{ width: "35%" }}>
                <label style={{ fontWeight: "bold" }}>{animalDets.breed}</label>
              </td>
              <td style={{ width: "15%" }}>
                <label style={{ textDecoration: "underline" }}>Age:</label>
              </td>
              <td style={{ width: "35%" }}>
                <label style={{ fontWeight: "bold" }}>{animalDets.age}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label style={{ textDecoration: "underline" }}>
                  Date Listed:
                </label>
              </td>
              <td>
                <label style={{ fontWeight: "bold" }}>
                  {animalDets.dateListed}
                </label>
              </td>
              <td>
                <label style={{ textDecoration: "underline" }}>
                  Application Status:
                </label>
              </td>
              <td>
                <label style={{ fontWeight: "bold" }}>
                  {applicationStatus}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div
          style={{
            border: "1px solid black",
            height: "100%",
            width: "90%",
            overflowY: "scroll",
            overflowX: "auto",
            marginBottom: "1em",
          }}
        >
          {applicantMessages.map((messageObj) => (
            <div
              key={messageObj.messageID}
              style={{
                borderRadius: 8,
                backgroundColor:
                  messageObj.new === "Yes" &&
                  messageObj.type === "From-Rescue-To-Applicant"
                    ? "#38015c"
                    : "pink",
                color:
                  messageObj.new === "Yes" &&
                  messageObj.type === "From-Rescue-To-Applicant"
                    ? "grey"
                    : "black",
                margin: 10,
                padding: 5,
              }}
            >
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ width: "40%" }}>
                      <h2>
                        {messageObj.date} - {messageObj.time}
                      </h2>
                    </td>
                    <td style={{ width: "20%" }}>
                      {messageObj.new === "Yes" &&
                        messageObj.type === "From-Rescue-To-Applicant" && (
                          <h2 style={{ color: "red" }}>(New Message)</h2>
                        )}
                    </td>
                    <td style={{ width: "40%" }}></td>
                  </tr>
                </tbody>
              </table>
              <h2>Sent by {messageObj.senderUserName}</h2>
              <pre
                style={{
                  overflowX: "auto",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {messageObj.message}
              </pre>
              <br></br>
              <table style={{ borderSpacing: "10px" }}>
                <tbody>
                  <tr>
                    {messageObj.attachmentsArray.map((attachment) => (
                      <td
                        key={attachment.url}
                        style={{
                          border: "1px solid black",
                          // margin: "1em",
                          padding: "0.5em",
                          background: "grey",
                        }}
                      >
                        <a
                          href={attachment.url}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {attachment.name}
                        </a>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
        {openNewMessageModal && (
          <NewMessageModal
            applicantID={params.applicantID}
            animalID={params.animalID}
            setOpenNewMessageModal={setOpenNewMessageModal}
            addMessageToList={addMessageToList}
            nameOnEmail={rescueDetails.rescueName}
            emailAddress={rescueDetails.rescueEmail}
            pagename="applicant-details"
            senderUserID={authCtx.userID}
            senderUserName={applicant.name}
            messageType="From-Applicant-To-Rescue"
            rescueIDForMessages={animalsRescueID.current}
          />
        )}
      </div>
    );
  }
}

export default AppAnimalDetail;
