import { useParams, useHistory } from "react-router-dom";
import { useCallback, useEffect, useState, useContext, useRef } from "react";
import LoadingSpinner from "./LoadingSpinner";
import AuthContext from "../store/auth-context";
import Button from "../UI/Button";

function RescueDashboard(props) {
  // console.log("Re-rendering component");
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [animals, setAnimals] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [copyButtonLabel, setCopyButtonLabel] = useState("Copy Link");

  const [filterPreference, setFilterPreference] = useState("All_Animals");

  const rescueID = useRef("");

  let loadedAnimals = [];

  // Function to get the list of animals for this rescue.
  const fetchAnimalsHandler = useCallback(async (rescueID1) => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="rescueID"&equalTo="' +
          rescueID1 +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of animals!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      loadedAnimals = [];

      for (const key in data) {
        loadedAnimals.push({
          animalID: key,
          animalName: data[key].animalName,
          dateListed: data[key].dateListed,
          status: data[key].status,
          dateAdopted: data[key].dateAdopted,
          breed: data[key].breed,
          age: data[key].age,
          imageURL: data[key].imageURL,
          dateAdded: data[key].dateAdded,
          type: data[key].type,
          newmessgs: 0,
          flaggedmessgs: 0,
        });
      }

      loadedAnimals.sort(compare);

      // setAnimals(loadedAnimals);

      // Now call the function that gets the info on the rescue organization (name, etc..)
      fetchRescueInfo(rescueID1);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get info about which rescue the user works for
  const fetchRescueForUser = useCallback(async (rescueUserID) => {
    // console.log(`Fetching Details Of Rescue Users ${rescueUserID}...`);

    setIsLoading(true);
    setError(null);
    const url =
      'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescue_users.json?orderBy="$key"&equalTo="' +
      rescueUserID +
      '"&auth=' +
      authCtx.token;
    // console.log("rescueUserID=", rescueUserID);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving rescue user info!!"
        );
      }

      const data = await response.json();

      // console.log("data=", data);

      let isAdmin = false;
      let name = "";
      for (const key in data) {
        // console.log(`>> Name for position ${index} =`, data[key].name);
        rescueID.current = data[key].rescue_id;
        isAdmin = data[key].isadmin;
        name = data[key].name;
      }

      // console.log("Rescue ID is ", rescueID.current);
      // console.log("isAdmin=", isAdmin);
      // console.log(
      //   "running setRescueIDHandler 3 - rescueid = ",
      //   rescueID.current
      // );
      authCtx.setRescueIDHandler(rescueID.current);
      authCtx.setUserIsAdminHandler(isAdmin);
      authCtx.setUserNameHandler(name);

      // Now call the function that gets the list of animals for this rescue
      fetchAnimalsHandler(rescueID.current);
      //

      //
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get info about the rescue (name, etc..)
  const fetchRescueInfo = useCallback(async (rescueID1) => {
    // console.log(`Fetching Details Of Rescue ${rescueID1}...`);

    setIsLoading(true);
    setError(null);
    const url =
      'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues.json?orderBy="$key"&equalTo="' +
      rescueID1 +
      '"&auth=' +
      authCtx.token;
    // console.log("rescueID1=", rescueID1);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving rescue details!!!"
        );
      }

      const data = await response.json();

      // console.log("Rescue Data=", data);

      let rescueName = "Unknown";

      for (const key in data) {
        rescueName = data[key].name;
      }

      // console.log("Rescue Name is ", rescueName);
      authCtx.setRescueOrgNameHandler(rescueName);

      fetchNumberOfNewMessages(rescueID1);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the number of new messages for each animal.
  const fetchNumberOfNewMessages = useCallback(async (rescueID1) => {
    // console.log("Fetching List Of New Messages...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/messages.json?orderBy="rescueID"&equalTo="' +
          rescueID1 +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of messages!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      // console.log("data=", data);
      for (const key in data) {
        // console.log(data[key].new, data[key].type);
        if (
          data[key].new === "Yes" &&
          data[key].type === "From-Applicant-To-Rescue"
        ) {
          loadedAnimals.forEach((e, idx) => {
            if (e.animalID === data[key].animalID) {
              loadedAnimals[idx].newmessgs = loadedAnimals[idx].newmessgs + 1;
            }
          });
        }

        if (data[key].flagged === true) {
          loadedAnimals.forEach((e, idx) => {
            if (e.animalID === data[key].animalID) {
              loadedAnimals[idx].flaggedmessgs =
                loadedAnimals[idx].flaggedmessgs + 1;
            }
          });
        }
      }

      setAnimals(loadedAnimals);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // Set the filter to the value saved in the context
    setFilterPreference(authCtx.filterPref);

    // console.log(
    //   "Calling fetchRescueForUser - rescueUserID=",
    //   params.rescueUserID
    // );

    fetchRescueForUser(params.rescueUserID);
  }, [fetchRescueForUser]);

  function onChangeFilter(e) {
    setFilterPreference(e.target.value);

    authCtx.filterSetHandler(e.target.value);
  }

  // console.log("authCtx.filterPref=", authCtx.filterPref);

  const animalsToShow = [];

  if (authCtx.filterPref === "All_Animals") {
    animals.forEach((e) => {
      animalsToShow.push(e);
    });
  } else if (authCtx.filterPref === "Available_Animals_Only") {
    animals.forEach((e) => {
      if (e.status === "Available") {
        animalsToShow.push(e);
      }
    });
  } else if (authCtx.filterPref === "Adoption_Pending_Animals_Only") {
    animals.forEach((e) => {
      if (e.status === "Adoption Pending") {
        animalsToShow.push(e);
      }
    });
  } else if (authCtx.filterPref === "On_Hold_Animals_Only") {
    animals.forEach((e) => {
      if (e.status === "On Hold") {
        animalsToShow.push(e);
      }
    });
  } else {
    animals.forEach((e) => {
      if (e.status === "Adopted") {
        animalsToShow.push(e);
      }
    });
  }

  function onChangePassword() {
    history.push("/change-password/rescue");
  }

  function onAnimalDetail(animal) {
    history.push("/animal_details/" + animal.animalID);
  }

  function onRescueUsers() {
    history.push("/rescue-users/" + rescueID.current);
  }

  function onListAllApplicants() {
    history.push("/applicants-list/" + rescueID.current);
  }

  function onAddAnimal() {
    history.push("/add-animal/none");
  }

  function compare(a, b) {
    if (a.animalName < b.animalName) {
      return -1;
    }
    if (a.animalName > b.animalName) {
      return 1;
    }
    return 0;
  }

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  function onUpdateRescueInfo() {
    history.push("/update-rescue-info/" + authCtx.rescueID);
  }

  function copyLink() {
    setCopyButtonLabel("Copied...");
    navigator.clipboard.writeText(
      "https://www.plenty-of-pooches.org/apply-to-adopt/ReS" + authCtx.rescueID
    );
  }

  // console.log("animalToShow=", ...animalsToShow);

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <button onClick={onLogout}>Login Again</button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "20%" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {authCtx.userIsAdmin && (
                              <Button onClick={onUpdateRescueInfo}>
                                Update Rescue Info
                              </Button>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {authCtx.userIsAdmin && (
                              <Button onClick={onRescueUsers}>
                                View Users
                              </Button>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Button onClick={onAddAnimal}>Add Animal</Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "7vh",
                      fontSize: "6vh",
                      fontWeight: "bold",
                    }}
                  >
                    {authCtx.rescueOrgName}
                  </td>
                  <td style={{ width: "20%" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button onClick={onLogout}>Logout</Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Button onClick={onChangePassword}>
                              Change Password
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Button onClick={onListAllApplicants}>
                              Show All Applicants
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br></br>
        {/* <label>User ID: {authCtx.authUserID}</label> */}
        <div style={{ textAlign: "center" }}>
          <label style={{ textDecoration: "underline", paddingRight: "2em" }}>
            URL:
          </label>
          <label style={{ fontWeight: "bold", paddingRight: "1em" }}>
            https://www.plenty-of-pooches.org/apply-to-adopt/ReS
            {authCtx.rescueID}
          </label>
          <button onClick={copyLink}>{copyButtonLabel}</button>
        </div>
        <div style={{ textAlign: "center", color: "purple" }}>
          <label>
            (Applicant can use this link to put in an application to your
            rescue)
          </label>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>Welcome back, {authCtx.userName}</h2>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label style={{ padding: "10px", textDecoration: "underline" }}>
            Filter
          </label>
          <select
            name="filter"
            id="filter"
            value={filterPreference}
            onChange={onChangeFilter}
          >
            <option value="All_Animals">All Animals</option>
            <option value="Available_Animals_Only">
              Available Animals Only
            </option>
            <option value="Adoption_Pending_Animals_Only">
              Adoption Pending Animals Only
            </option>
            <option value="Adopted_Animals_Only">Adopted Animals Only</option>
            <option value="On_Hold_Animals_Only">On Hold Animals Only</option>
          </select>
        </div>
        <br></br>
        <br></br>
        <div>
          <table style={{ width: "calc(100% - 1em)" }}>
            <tbody>
              <tr
                key="header"
                style={{ backgroundColor: "#38015c", color: "white" }}
              >
                {/* <td style={{ border: "1px solid black" }}>Animal ID</td> */}
                <td style={{ border: "1px solid black", width: "30%" }}>
                  Name
                </td>
                <td style={{ border: "1px solid black", width: "15%" }}>
                  Date Listed
                </td>
                <td style={{ border: "1px solid black", width: "20%" }}>
                  Status
                </td>
                <td style={{ border: "1px solid black", width: "10%" }}>
                  New Messages
                </td>
                <td style={{ border: "1px solid black", width: "10%" }}>
                  Flagged Messages
                </td>
                <td style={{ border: "1px solid black", width: "15%" }}>
                  Date Adopted
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ height: "42vh", overflowY: "scroll" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                {animalsToShow.map((animal) => (
                  <tr key={animal.animalID}>
                    {/* <td style={{ border: "1px solid black" }}>{animal.animalID}</td> */}
                    <td style={{ border: "1px solid black", width: "30%" }}>
                      <label
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          fontWeight: "bold",
                        }}
                        onClick={onAnimalDetail.bind(this, animal)}
                      >
                        {animal.animalName}
                      </label>
                    </td>
                    <td style={{ border: "1px solid black", width: "15%" }}>
                      {animal.dateListed}
                    </td>
                    <td style={{ border: "1px solid black", width: "20%" }}>
                      {animal.status}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        width: "10%",
                        backgroundColor:
                          animal.newmessgs === 0 ? "white" : "pink",
                      }}
                    >
                      {animal.newmessgs === 0 ? "" : animal.newmessgs}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        width: "10%",
                        backgroundColor:
                          animal.flaggedmessgs === 0 ? "white" : "pink",
                      }}
                    >
                      {animal.flaggedmessgs === 0 ? "" : animal.flaggedmessgs}
                    </td>
                    <td style={{ border: "1px solid black", width: "15%" }}>
                      {animal.dateAdopted}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default RescueDashboard;
