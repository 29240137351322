import { useParams, useHistory } from "react-router-dom";
import { useCallback, useEffect, useState, useContext } from "react";
import AuthContext from "../store/auth-context";
import LoadingSpinner from "./LoadingSpinner";

import Button from "../UI/Button";

function RescueUsers() {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [rescueUsers, setRescueUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get list of users for this rescue.
  const fetchRescueUsers = useCallback(async () => {
    // console.log("Fetching List Of Rescue Users...");

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescue_users.json?orderBy="rescue_id"&equalTo="' +
          params.rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of rescue users!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const loadedRescueUsers = [];

      for (const key in data) {
        if (data[key].active) {
          loadedRescueUsers.push({
            key: key,
            userName: data[key].name,
            isAdmin: data[key].isadmin,
            authUserId: key,
            active: data[key].active,
          });
        }
      }

      // console.log("loadedRescueUsers=", loadedRescueUsers);
      loadedRescueUsers.sort(compare);

      setRescueUsers(loadedRescueUsers);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchRescueUsers();
  }, [fetchRescueUsers]);

  const deleteUserHandler = useCallback(async (user) => {
    // console.log("authCtx=", authCtx);
    // setPageShowing("RescueDashboard");
    // console.log("Deleting user=", JSON.stringify(user));
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://us-central1-plenty-of-pooches.cloudfunctions.net/deleteuserbyuid",
        {
          method: "POST",
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while trying to delete the user!"
        );
      }

      // const data = await response.json();

      // Now call the function that will update (patch) the data to indicate that this user is Inactive.
      setUserToInactive(user.uid);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function deleteUser(userToDelete) {
    alert("deleting user " + userToDelete);

    const user = {
      uid: userToDelete,
    };

    deleteUserHandler(user);
  }

  // Function to to set the user to 'Inactive'
  const setUserToInactive = useCallback(async (userId) => {
    // console.log(`Setting user ${userId} to 'Inacive'...`);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/rescue_users/" +
          userId +
          ".json?" +
          '"&auth=' +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify({
            active: false,
            changedBy: authCtx.userID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while trying to set rescue user to inactive!"
        );
      }

      const data = await response.json();

      // console.log("User successfully set to 'Inactive");

      fetchRescueUsers();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function onAddUserHandler() {
    history.push("/add-user");
  }

  function compare(a, b) {
    if (a.userName < b.userName) {
      return -1;
    }
    if (a.animalName > b.animalName) {
      return 1;
    }
    return 0;
  }

  // console.log("rescueUsers=", rescueUsers);

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  function backToDashboard() {
    history.push("/rescue-dashboard/" + authCtx.userID);
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "20%" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button onClick={backToDashboard}>
                              Back to Dashboard
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Button onClick={onAddUserHandler}>Add User</Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "7vh",
                      fontSize: "4vh",
                      fontWeight: "bold",
                    }}
                  >
                    Rescue's Users
                  </td>
                  <td style={{ width: "20%" }}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br></br>
        <br></br>
        <div>
          <div
            style={{
              width: "calc(100% - 1em)",
              alignContent: "left",
            }}
          >
            <table
              style={{
                width: "60em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <tbody>
                <tr
                  key="header"
                  style={{ backgroundColor: "#38015c", color: "white" }}
                >
                  <td style={{ border: "1px solid black", width: "50%" }}>
                    Name
                  </td>
                  <td style={{ border: "1px solid black", width: "30%" }}>
                    Administrator
                  </td>
                  <td
                    style={{
                      width: "20%",
                      backgroundColor: "white",
                    }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              width: "100%",
              height: "50vh",
              overflow: "scroll",
            }}
          >
            <table
              style={{
                width: "60em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <tbody>
                {rescueUsers.map((user) => (
                  <tr key={user.key}>
                    <td style={{ border: "1px solid black", width: "50%" }}>
                      <label>{user.userName}</label>
                    </td>
                    <td style={{ border: "1px solid black", width: "30%" }}>
                      <label>{user.isAdmin ? "Y" : ""}</label>
                    </td>
                    <td style={{ width: "20%" }}>
                      {authCtx.authUserID !== user.authUserId &&
                        user.active === true && (
                          <button
                            onClick={deleteUser.bind(this, user.authUserId)}
                          >
                            Delete
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default RescueUsers;
