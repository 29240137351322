import { useParams, useHistory } from "react-router-dom";
import { useCallback, useEffect, useState, useContext, useRef } from "react";
import AuthContext from "../store/auth-context";
import LoadingSpinner from "./LoadingSpinner";
import NewMessageModal from "./NewMessageModal";

import Button from "../UI/Button";

function ApplicantDetails() {
  // console.log("Refreshing component");

  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [applicant, setApplicant] = useState("");
  const [applicantMessages, setApplicantMessages] = useState([]);

  const [openNewMessageModal, setOpenNewMessageModal] = useState(false);

  const [animalName, setAnimalName] = useState("");

  const tempMessages = useRef([]);

  const nonStateAppID = useRef("");
  const [applicationID, setApplicationID] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  const initialMessage = useRef("");

  // Fetch the details of this applicant
  const fetchApplicantDetailsHandler = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant details!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      let applicantTemp = {};

      for (const key in data) {
        applicantTemp = {
          key: key,
          address: data[key].address,
          name: data[key].name,
          phone: data[key].phone,
          email: data[key].email,
        };
      }

      // console.log("applicantTemp = ", applicantTemp);

      setApplicant(applicantTemp);

      // Now fetch the message history for this applicant and animal
      fetchMessageHistory();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch the messages for this this applicant regarding this animal
  const fetchMessageHistory = useCallback(async () => {
    // console.log("Fetching List Of Messages...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/messages.json?orderBy="applicantID"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant messages!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      tempMessages.current = [];

      for (const key in data) {
        if (data[key].animalID === params.animalID) {
          const tmp = data[key].date.split("/");
          const intDate = tmp[2] + tmp[0] + tmp[1] + "_" + data[key].time;
          tempMessages.current.push({
            messageID: key,
            animalID: data[key].animalID,
            senderUserID: data[key].senderUserID,
            senderUserName: data[key].senderUserName,
            type: data[key].type,
            date: data[key].date,
            time: data[key].time,
            read: data[key].read,
            new: data[key].new,
            message: data[key].message,
            intDate: intDate,
            attachmentsArray: data[key].attachmentsArray
              ? data[key].attachmentsArray
              : [],
            flagged: data[key].flagged,
          });
        }
      }

      // console.log("applicantTemp = ", applicantTemp);

      setApplicantMessages(tempMessages.current);

      fetchAnimalNameHandler();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch animal's name to display
  const fetchAnimalNameHandler = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="$key"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error("Something went wrong while retrieving animal's name!");
      }

      const data = await response.json();

      // console.log(">> fetchAnimalNameHandler - data=", data);

      for (const key in data) {
        setAnimalName(data[key].animalName);
      }

      // Now call the function that will get the status of the application and the application ID.
      getApplicationId();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to loop through messages and set any unread messages to read in the database.
  function setUnreadToRead() {
    tempMessages.current.forEach((messageObj) => {
      if (
        messageObj.new === "Yes" &&
        messageObj.type === "From-Applicant-To-Rescue"
      ) {
        // Call the function to set this message to read
        updateMessageToRead(messageObj.messageID);
      }
    });
  }

  // Function to set the specified message to 'read'
  const updateMessageToRead = useCallback(async (messageID) => {
    // console.log(`Setting message with ID ${messageID} to read.`);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/messages/" +
          messageID +
          ".json?" +
          '"&auth=' +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify({
            new: "No",
            changedBy: authCtx.userID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while marking messages as 'read'!"
        );
      }

      const data = await response.json();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchApplicantDetailsHandler();
  }, [fetchApplicantDetailsHandler]);

  function onViewApplications() {
    history.push(
      "/view-application/" +
        authCtx.rescueID +
        "/" +
        params.animalID +
        "/" +
        params.applicantID
    );
  }

  function addMessageToList(messageObject) {
    // console.log("running addMessageToList - messageObject=", messageObject);
    setApplicantMessages((prev) => {
      const tmp = messageObject.date.split("/");
      const intDate = tmp[2] + tmp[0] + tmp[1] + "_" + messageObject.time;
      const tmp1 = [
        ...prev,
        {
          messageID: intDate,
          animalID: messageObject.animalID,
          senderUserID: messageObject.senderUserID,
          senderUserName: messageObject.senderUserName,
          type: messageObject.type,
          date: messageObject.date,
          time: messageObject.time,
          read: messageObject.read,
          new: messageObject.new,
          message: messageObject.message,
          intDate: intDate,
          attachmentsArray: messageObject.attachmentsArray,
          flagged: messageObject.flagged,
        },
      ];
      return tmp1;
    });
  }

  function compare(a, b) {
    if (a.intDate > b.intDate) {
      return -1;
    }
    if (a.intDate < b.intDate) {
      return 1;
    }
    return 0;
  }

  applicantMessages.sort(compare);

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  function backToAnimalDetails() {
    history.push("/animal_details/" + params.animalID);
  }

  // This function updates the 'flagged' flag for the specified message.
  const flagMessageHandler = useCallback(async (payload) => {
    // console.log("changeAnimalHandler - tempQuestions=", tempQuestions);

    // console.log("GOT HERE - messageID=", payload);
    // setIsLoading(true);
    // setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/messages/" +
          payload.messageID +
          ".json?auth=" +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify({
            flagged: payload.flag,
            changedBy: authCtx.userID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while updating the message flag!"
        );
      }

      const data = await response.json();

      // set the flag in the applicantMessages array so that we don't have to go out and download
      // all the messages again.
      // setApplicantMessages((messages) => {
      //   const tmpMessArray = [...messages];
      //   tmpMessArray[payload.index].flag = payload.flag;
      //   return tmpMessArray;
      // });
    } catch (error) {
      // setError(error.message);
    }
    // setIsLoading(false);
  }, []);

  function onViewEditApplicantFolder() {
    history.push("/applicant-folder/" + params.applicantID);
  }

  // This is where we get the application ID that we will need to update the application.
  const getApplicationId = useCallback(async () => {
    // console.log("Getting application ID and current status...");

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applications.json?orderBy="animalID"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of applications!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      for (const key in data) {
        if (data[key].applicantID === params.applicantID) {
          nonStateAppID.current = key;
          setApplicationID(key);
          setApplicationStatus(data[key].status);
        }
      }

      // Now call the function that will set all unread messages to read.
      setUnreadToRead();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function applicationStatusChanged(event) {
    // console.log("Status Changed to ", event.target.value);

    updateApplicationStatus(event.target.value);
  }

  // This function updates the application Status in the database
  const updateApplicationStatus = useCallback(async (appStatus) => {
    // console.log("applicationID = ", applicationID);
    // console.log("nonStateAppID.current = ", nonStateAppID.current);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/applications/" +
          nonStateAppID.current +
          ".json?auth=" +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify({ status: appStatus }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing rescue to database!"
        );
      }

      const data = await response.json();

      setApplicationStatus(appStatus);

      // Now call the new message modal.  The assumption is that the user will want to send a message to the adopter letting them know of the status change.
      initialMessage.current = `*** Status Update Notification!\n\n The status of your application has been changed to '${appStatus}'`;
      setOpenNewMessageModal(true);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button onClick={backToAnimalDetails}>
                            Back to Animal Details
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button
                            onClick={() => {
                              initialMessage.current = "";
                              setOpenNewMessageModal(true);
                            }}
                          >
                            Send New Message
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ width: "60%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "7vh",
                      fontSize: "4vh",
                      fontWeight: "bold",
                    }}
                  >
                    <label style={{ textDecoration: "underline" }}>
                      {applicant.name}
                    </label>
                    <label> application to adopt '{animalName}'</label>
                  </div>
                </td>
                <td style={{ width: "20%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button onClick={onViewApplications}>
                            View Application
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button onClick={onViewEditApplicantFolder}>
                            Applicant Details
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* {params.special === "special" && (
                    <Button onClick={closeApplicantDetail}>Dashboard</Button>
                  )} */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br></br>
        <table
          style={{
            width: "100%",
            // marginLeft: "25vw",
            marginTop: "3vw",
            // border: "1px solid red",
          }}
        >
          <tbody>
            <tr>
              <td style={{ width: "15%" }}>
                <label style={{ textDecoration: "underline" }}>Phone:</label>
              </td>
              <td style={{ width: "35%" }}>
                <label style={{ fontWeight: "bold" }}>{applicant.phone}</label>
              </td>
              <td style={{ width: "15%" }}>
                <label style={{ textDecoration: "underline" }}>Email:</label>
              </td>
              <td style={{ width: "35%" }}>
                <label style={{ fontWeight: "bold" }}>{applicant.email}</label>
              </td>
            </tr>
            <tr>
              <td style={{ width: "15%" }}>
                <label style={{ textDecoration: "underline" }}>Address:</label>
              </td>
              <td style={{ width: "35%" }}>
                <label style={{ fontWeight: "bold" }}>
                  {applicant.address}
                </label>
              </td>
              <td style={{ width: "15%" }}>
                <label style={{ textDecoration: "underline" }}>
                  Status of Appication:
                </label>
              </td>
              <td style={{ width: "35%" }}>
                <select
                  name="status"
                  id="status"
                  // ref={companyNum}
                  value={applicationStatus}
                  onChange={applicationStatusChanged}
                >
                  <option value="Application Submitted">
                    Application Submitted
                  </option>
                  <option value="Under Review By Rescue">
                    Under Review By Rescue
                  </option>
                  <option value="Waiting For Adopter To Respond">
                    Waiting For Adopter To Respond
                  </option>
                  <option value="Waiting For Home Visit">
                    Waiting For Home Visit
                  </option>
                  <option value="Animal Adopted By Somone Else">
                    Animal Adopted By Somone Else
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div
          style={{
            border: "1px solid black",
            height: "100%",
            width: "90%",
            overflowY: "scroll",
            overflowX: "auto",
            marginBottom: "1em",
          }}
        >
          {applicantMessages.map((messageObj, indx) => (
            <div
              key={messageObj.messageID}
              style={{
                borderRadius: 8,
                backgroundColor:
                  messageObj.new === "Yes" &&
                  messageObj.type === "From-Applicant-To-Rescue"
                    ? "#38015c"
                    : "pink",
                color:
                  messageObj.new === "Yes" &&
                  messageObj.type === "From-Applicant-To-Rescue"
                    ? "grey"
                    : "black",
                margin: 10,
                padding: 5,
              }}
            >
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ width: "40%" }}>
                      <h2>
                        {messageObj.date} - {messageObj.time}
                      </h2>
                    </td>
                    <td style={{ width: "20%" }}>
                      {messageObj.new === "Yes" &&
                        messageObj.type === "From-Applicant-To-Rescue" && (
                          <h2 style={{ color: "red" }}>(New Message)</h2>
                        )}
                    </td>
                    <td style={{ width: "40%" }}>
                      <label htmlFor="flag">Flag?</label>
                      <input
                        type="checkbox"
                        id="flag"
                        defaultChecked={messageObj.flagged ? "checked" : ""}
                        onChange={flagMessageHandler.bind(this, {
                          index: indx,
                          messageID: messageObj.messageID,
                          flag: !messageObj.flagged,
                        })}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>Sent by {messageObj.senderUserName}</h2>
              <pre
                style={{
                  overflowX: "auto",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {messageObj.message}
              </pre>
              <br></br>
              <table style={{ borderSpacing: "10px" }}>
                <tbody>
                  <tr>
                    {messageObj.attachmentsArray.map((attachment) => (
                      <td
                        key={attachment.url}
                        style={{
                          border: "1px solid black",
                          // margin: "1em",
                          padding: "0.5em",
                        }}
                      >
                        <a
                          href={attachment.url}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {attachment.name}
                        </a>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
        {openNewMessageModal && (
          <NewMessageModal
            applicantID={params.applicantID}
            animalID={params.animalID}
            setOpenNewMessageModal={setOpenNewMessageModal}
            addMessageToList={addMessageToList}
            nameOnEmail={applicant.name}
            emailAddress={applicant.email}
            pagename="app-animal-detail"
            senderUserID={authCtx.userID}
            senderUserName={authCtx.userName}
            messageType="From-Rescue-To-Applicant"
            rescueIDForMessages={authCtx.rescueID}
            initialMessage={initialMessage.current}
          />
        )}
      </div>
    );
  }
}

export default ApplicantDetails;
