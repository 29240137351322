import { useParams, useHistory } from "react-router-dom";
import { useState, useRef, useContext, useCallback, useEffect } from "react";

import AuthContext from "../store/auth-context";
import classes from "./AuthForm.module.css";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase";

const AuthForm = (props) => {
  var popLogo = require("../images/pop.png");

  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [error, setError] = useState(null);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const nameInputRef = useRef();
  const addressInputRef = useRef();
  const phoneInputRef = useRef();

  const rescueID = useRef("");

  const loginToken = useRef("");

  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Function to sign-up a new applicant
  const register = async (
    enteredEmail,
    enteredPassword,
    enteredName,
    enteredAddress,
    enteredPhone
  ) => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        enteredEmail,
        enteredPassword
      );
      console.log(user._tokenResponse);
      setIsLoading(false);
      authCtx.setAuthUserIDHandler(user._tokenResponse.localId);
      loginToken.current = user._tokenResponse.idToken;
      authCtx.loginHandler(user._tokenResponse.idToken);
      authCtx.setRefreshTokenHandler(user._tokenResponse.refreshToken);

      let userData = {
        userID: user._tokenResponse.localId,
        name: enteredName,
        address: enteredAddress,
        phone: enteredPhone,
        email: enteredEmail,
      };

      console.log(
        "Calling addUserandler. data.idToken=",
        user._tokenResponse.idToken
      );
      addUserHandler(
        user._tokenResponse.localId,
        userData,
        user._tokenResponse.idToken
      );
    } catch (error) {
      alert(`ERROR- ${error.message}`);
      setIsLoading(false);
    }
  };

  // Function to log in an applicant or a rescue user
  const login = async (enteredEmail, enteredPassword) => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        enteredEmail,
        enteredPassword
      );
      console.log("user=", user);
      console.log("user._tokenResponse", user._tokenResponse);
      setIsLoading(false);
      authCtx.setAuthUserIDHandler(user._tokenResponse.localId);
      loginToken.current = user._tokenResponse.idToken;
      authCtx.loginHandler(user._tokenResponse.idToken);
      authCtx.setRefreshTokenHandler(user._tokenResponse.refreshToken);

      fetchRescueUsersInfo(
        user._tokenResponse.localId,
        user._tokenResponse.idToken
      );
    } catch (error) {
      alert(`ERROR- ${error.message}`);
      setIsLoading(false);
    }
  };

  const parseCookie = (str) =>
    str
      .split(";")
      .map((v) => v.split("="))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {});

  useEffect(() => {
    // Check to see if there is a cookie with a valid token.  If there is, then the user is already
    // logged in and doesn't need to log in again.
    let cookieString = document.cookie;

    // console.log("cookieString=", cookieString);

    let parsedCookie = {};
    if (cookieString !== "") {
      parsedCookie = parseCookie(cookieString);
    }

    // console.log("parsedCookie.token=", parsedCookie.token);

    // AL if (
    // AL  cookieString === "" ||
    // AL  parsedCookie.token === "" ||
    // AL  parsedCookie.token === "undefined" ||
    // AL  parsedCookie.token === undefined
    // AL ) {
    if (localStorage.getItem("token") === null) {
      // User is not logged in, so do nothing
    } else {
      // There is a token stored in the cookie.  So, don't make the user log in again and set the token
      // in the context to be the token in the cookie.
      // console.log(
      //   "User is already logged in - parsedCookie.token=",
      //   parsedCookie.token
      // );
      // console.log(parsedCookie);
      // console.log("authuserid=", parsedCookie.authuserid);
      // console.log("token=", parsedCookie.token);
      // console.log("refreshtoken=", parsedCookie.refreshtoken);
      // console.log("userid=", parsedCookie.userid);
      // AL authCtx.loginHandler(parsedCookie.token);
      // AL authCtx.setAuthUserIDHandler(parsedCookie.authuserid);
      // AL authCtx.setRefreshTokenHandler(parsedCookie.refreshtoken);
      // AL authCtx.setUserIDHandler(parsedCookie.userid);
      // AL authCtx.setUserNameHandler(parsedCookie.username);
      authCtx.loginHandler(localStorage.getItem("token"));
      authCtx.setAuthUserIDHandler(localStorage.getItem("authuserid"));
      authCtx.setRefreshTokenHandler(localStorage.getItem("refreshtoken"));
      authCtx.setUserIDHandler(localStorage.getItem("userid"));
      authCtx.setUserNameHandler(localStorage.getItem("username"));

      // AL if (parsedCookie.rescueid === undefined) {
      if (localStorage.getItem("rescueid") === null) {
        //////////////////////////
        // This is an applicant //
        //////////////////////////
        if (params.loginType === "apply-to-adopt") {
          // This is a user who came from the 'apply-to-adopt' page, so take them next to the
          // CompleteApplication page.
          history.replace(
            "/complete-application/" +
              params.secondParam +
              "/" +
              params.animalID
          );
        } else if (params.loginType === "app-animal-detail") {
          // This is an applicant who has clicked on a link in an e-mail notification of a new message.
          // So, take the user to the page that shows the message.
          history.replace(
            "/app-animal-detail/" +
              params.secondParam +
              "/" +
              params.animalID +
              "/special"
          );
        } else {
          // Now navigate to the appliicant dashboard.
          // AL history.replace("/applicant-dashboard/" + parsedCookie.userid);
          history.replace(
            "/applicant-dashboard/" + localStorage.getItem("userid")
          );
        }
      } else {
        ///////////////////////////
        // This is a rescue user //
        ///////////////////////////
        // AL authCtx.setRescueIDHandler(parsedCookie.rescueid);
        // console.log(
        //   "running setRescueIDHandler 1 - rescueid = ",
        //   localStorage.getItem("rescueid")
        // );
        authCtx.setRescueIDHandler(localStorage.getItem("rescueid"));
        if (params.loginType === "applicant-details") {
          // This is a rescue user who has clicked on a link in an e-mail letting them know that there
          // is a new message from an applicant about one of their animals.
          // console.log(
          //   "Opening applicant-details page",
          //   "/applicant-details/" +
          //     params.secondParam +
          //     "/" +
          //     params.animalID +
          //     "/special"
          // );
          history.replace(
            "/applicant-details/" +
              params.secondParam +
              "/" +
              params.animalID +
              "/special"
          );
        } else {
          // AL history.replace("/rescue-dashboard/" + parsedCookie.userid);
          // console.log("GOT HERE 1 - userid=", localStorage.getItem("userid"));
          history.replace(
            "/rescue-dashboard/" + localStorage.getItem("userid")
          );
        }
      }
    }
  }, []);

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    let enteredName;
    let enteredAddress;
    let enteredPhone;

    if (!isLogin) {
      enteredName = nameInputRef.current.value;
      enteredAddress = addressInputRef.current.value;
      enteredPhone = phoneInputRef.current.value;
    }

    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    // Add validation

    setIsLoading(true);

    let url;
    if (!isLogin) {
      // User is signign up with a new e-mail and password.
      register(
        enteredEmail,
        enteredPassword,
        enteredName,
        enteredAddress,
        enteredPhone
      );
    } else {
      login(enteredEmail, enteredPassword);
      // if (isLogin) {
      //   // User is attempting to sign in with an existing e-mail and password.
      //   url =
      //     "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyA5WcjKGcpPV47I_UteWJLWidGBDVS4Q9Q";
      // } else {
      //   // User is signign up with a new e-mail and password.
      //   url =
      //     "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyA5WcjKGcpPV47I_UteWJLWidGBDVS4Q9Q";
      // }
      // fetch(url, {
      //   method: "POST",
      //   body: JSON.stringify({
      //     email: enteredEmail,
      //     password: enteredPassword,
      //     returnSecureToken: true,
      //   }),
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then((res) => {
      //     setIsLoading(false);
      //     if (res.ok) {
      //       return res.json();
      //     } else {
      //       return res.json().then((data) => {
      //         let errorMessage = "Authentication Failed";
      //         if (data && data.error && data.error.message) {
      //           errorMessage = data.error.message;
      //         }
      //         throw new Error(errorMessage);
      //       });
      //     }
      //   })
      //   .then((data) => {
      //     // console.log("submitHandler - data=", data);
      //     authCtx.setAuthUserIDHandler(data.localId);
      //     loginToken.current = data.idToken;
      //     authCtx.loginHandler(data.idToken);
      //     authCtx.setRefreshTokenHandler(data.refreshToken);

      //     if (isLogin) {
      //       // This is an existing user, but we don't know if it is an applicant or a user from a rescue.
      //       fetchRescueUsersInfo(data.localId, data.idToken);
      //     } else {
      //       // Since we are adding a user here, it must be an applicant (not a rescue user), so add the user
      //       // to the 'applicants' table.

      //       let user = {
      //         userID: data.localId,
      //         name: enteredName,
      //         address: enteredAddress,
      //         phone: enteredPhone,
      //         email: enteredEmail,
      //       };

      //       console.log("Calling addUserandler. data.idToken=", data.idToken);
      //       addUserHandler(data.localId, user, data.idToken);
      //     }
      //   })
      //   .catch((error) => {
      //     setError(error.message);
      //   });
    }
  };

  // This function is used to add a applicant user to applicants database (using the same ID
  // is is used in the Auth database).
  const addUserHandler = useCallback(async (authUserID, user, idToken) => {
    // console.log("Setting up user in 'rescue_user' table... user=", user);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants/${authUserID}.json?auth=` +
          idToken,
        {
          method: "PATCH",
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing the user to the database!"
        );
      }

      const data = await response.json();

      console.log("data.name=", data.name);

      authCtx.setUserIDHandler(authUserID);

      alert("User successfully added to database");

      if (params.loginType === "apply-to-adopt") {
        // This is a user who came from the 'apply-to-adopt' page, so take them next to the
        // CompleteApplication page.
        history.replace(
          "/complete-application/" + params.secondParam + "/" + params.animalID
        );
      } else if (params.loginType === "app-animal-detail") {
        // This is an applicant who has clicked on a link in an e-mail notification of a new message.
        // So, take the user to the page that shows the message.
        history.replace(
          "/app-animal-detail/" + params.secondParam + "/" + params.animalID
        );
      } else {
        // Now navigate to the appliicant dashboard.
        history.replace("/applicant-dashboard/" + authUserID);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to look for user in the 'rescue_users' collection.  If found, then jump to
  // "RescueDasboard", otherwise look for user in the 'applicants' collection and, if found, just
  // to "ApplicantDashboard".
  const fetchRescueUsersInfo = useCallback(async (authUserID, idToken) => {
    // console.log(`Fetching Details Of Applicant ${applicantID}...`);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescue_users.json?orderBy="$key"&equalTo="' +
          authUserID +
          '"&auth=' +
          idToken
      );
      if (!response.ok) {
        throw new Error("Something went wrong while retrieving rescue users!");
      }

      const data = await response.json();

      // console.log("data=", data);

      let rescueUserId = "Unknown";
      let rescueUserName = "";
      for (const key in data) {
        // console.log(`>> Name =`, data[key].name);
        rescueUserId = key;
        rescueUserName = data[key].name;
      }

      if (rescueUserId === "Unknown") {
        // This means that the user doesn't exist in the "rescue_users" table and must therefore
        // be an applicant.
        console.log("This is an applicant");
        fetchApplicantUsersInfo(authUserID, idToken);
      } else {
        authCtx.setUserIDHandler(rescueUserId);
        authCtx.setUserNameHandler(rescueUserName);

        fetchRescueForUser(rescueUserId);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get applicant user's ID
  const fetchApplicantUsersInfo = useCallback(async (authUserID, idToken) => {
    // console.log(`Fetching Details Of Applicant ${applicantID}...`);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
          authUserID +
          '"&auth=' +
          idToken
      );
      if (!response.ok) {
        throw new Error("Something went wrong while retrieving applicant!");
      }

      const data = await response.json();

      // console.log("data=", data);

      let applicantUserId = "Unknown";
      for (const key in data) {
        // console.log(`>> Name for position ${index} =`, data[key].name);
        applicantUserId = key;
      }

      if (applicantUserId === "Unknown") {
        // This means that the user doesn't exist in the "applicants".
        alert("User does not exist.");
      } else {
        // alert("User is an applicant.  Id is " + applicantUserId);
        authCtx.setUserIDHandler(applicantUserId);

        if (params.loginType === "apply-to-adopt") {
          // This is a user who came from the 'apply-to-adopt' page, so take them next to the
          // CompleteApplication page.
          history.replace(
            "/complete-application/" +
              params.secondParam +
              "/" +
              params.animalID
          );
        } else if (params.loginType === "app-animal-detail") {
          // This is an applicant who has clicked on a link in an e-mail notification of a new message.
          // So, take the user to the page that shows the message.
          console.log("Calling app-animal-detail page!");
          history.replace(
            "/app-animal-detail/" +
              params.secondParam +
              "/" +
              params.animalID +
              "/special"
          );
        } else {
          // Now navigate to the appliicant dashboard.
          history.replace("/applicant-dashboard/" + applicantUserId);
        }
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get info about which rescue the user works for
  const fetchRescueForUser = useCallback(async (rescueUserID) => {
    // console.log(`Fetching Details Of Rescue Users ${rescueUserID}...`);

    setIsLoading(true);
    setError(null);
    try {
      // console.log("GOT HERE - loginToken.current = ", loginToken.current);
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescue_users.json?orderBy="$key"&equalTo="' +
          rescueUserID +
          '"&auth=' +
          loginToken.current
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving info about which rescue the user works for!"
        );
      }

      const data = await response.json();

      let isAdmin = false;
      let name = "";
      for (const key in data) {
        // console.log(`>> Name for position ${index} =`, data[key].name);
        rescueID.current = data[key].rescue_id;
        isAdmin = data[key].isadmin;
        name = data[key].name;
      }

      // console.log("Rescue ID is ", rescueID.current);
      // console.log("isAdmin=", isAdmin);
      // console.log(
      //   "running setRescueIDHandler 2 - rescueid = ",
      //   rescueID.current
      // );
      authCtx.setRescueIDHandler(rescueID.current);

      // alert("User is a rescue User.  Id is " + rescueUserId);
      if (params.loginType === "applicant-details") {
        // This is a rescue user who has clicked on a link in an e-mail letting them know that there
        // is a new message from an applicant about one of their animals.
        // console.log("Opening applicant-details page");
        history.replace(
          "/applicant-details/" +
            params.secondParam +
            "/" +
            params.animalID +
            "/special"
        );
      } else {
        history.replace("/rescue-dashboard/" + rescueUserID);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  if (error !== null) {
    return <h1>{error}</h1>;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                paddingTop: 10,
              }}
            >
              <img src={popLogo} style={{ maxHeight: "18vh" }} />
            </div>
            <div
              style={{
                flex: 3,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <div>
                <text style={{ fontSize: "4em" }}>Plenty Of Pooches</text>
              </div>
              <br></br>
              <div style={{ fontSize: "1em" }}>
                “Adding efficiency to the pet-adoption process in order to make
                life easier for the rescues, make the process simpler for
                adopters and ultimately lead to more animals being rescued”
              </div>
            </div>
            <div style={{ flex: 1 }}></div>
          </div>
        </div>
        <br></br>
        <div style={{ textAlign: "center" }}>V(3.0)</div>
        <div style={{ height: "73vh", overflowY: "scroll" }}>
          <section className={classes.auth}>
            <h1>{isLogin ? "Login" : "Sign Up"}</h1>
            {!isLogin && (
              <p style={{ color: "pink" }}>
                Note: If you work for a rescue organization, then you must be
                added by the administrator! Do not create an account here.
              </p>
            )}
            <form onSubmit={submitHandler}>
              {!isLogin && (
                <div className={classes.control}>
                  <label htmlFor="name">Name</label>
                  <input type="text" id="name" required ref={nameInputRef} />
                </div>
              )}
              {!isLogin && (
                <div className={classes.control}>
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    id="address"
                    required
                    ref={addressInputRef}
                  />
                </div>
              )}
              {!isLogin && (
                <div className={classes.control}>
                  <label htmlFor="Phone">Phone#</label>
                  <input type="tel" id="phone" required ref={phoneInputRef} />
                </div>
              )}
              <div className={classes.control}>
                <label htmlFor="email">Your Email</label>
                <input
                  type="email"
                  id="email"
                  required
                  ref={emailInputRef}
                  defaultValue={null}
                />
              </div>
              <div className={classes.control}>
                <label htmlFor="password">Your Password</label>
                <input
                  type="password"
                  id="password"
                  required
                  ref={passwordInputRef}
                />
              </div>
              <div className={classes.actions}>
                {!isLoading && (
                  <button>{isLogin ? "Login" : "Create Account"}</button>
                )}
                {isLoading && <p style={{ color: "white" }}>Sending Data...</p>}
                <button
                  type="button"
                  className={classes.toggle}
                  onClick={switchAuthModeHandler}
                >
                  {isLogin
                    ? "Create new account"
                    : "Login with existing account"}
                </button>
              </div>
            </form>
          </section>
          <br></br>
          <div style={{ textAlign: "center" }}>
            <text>Contact Us: </text>&nbsp;&nbsp;
            <a href="mailto: manager@plenty-of-pooches.com">
              manager@plenty-of-pooches.org
            </a>
          </div>
          <br></br>
        </div>
      </>
    );
  }
};

export default AuthForm;
