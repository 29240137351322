// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from "firebase/storage";
import { getFirestore } from "@firebase/firestore";

import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA5WcjKGcpPV47I_UteWJLWidGBDVS4Q9Q",
  authDomain: "plenty-of-pooches.firebaseapp.com",
  databaseURL: "https://plenty-of-pooches-default-rtdb.firebaseio.com",
  projectId: "plenty-of-pooches",
  storageBucket: "plenty-of-pooches.appspot.com",
  messagingSenderId: "998135926117",
  appId: "1:998135926117:web:ee0f372ad3860ae82e258c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcvTUwiAAAAAFxpnM4JNbyNyzPFdkWta5R7f3cm"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

export const storage = getStorage(app);

export const db = getFirestore(app);
