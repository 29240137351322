import { useParams, useHistory } from "react-router-dom";
import { useCallback, useEffect, useState, useContext } from "react";
import AuthContext from "../store/auth-context";
import LoadingSpinner from "./LoadingSpinner";

import Button from "../UI/Button";

function AnimalDetail() {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [animal, setAnimal] = useState({});
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [copyButtonLabel, setCopyButtonLabel] = useState("Copy Link");

  let loadedApplications = [];

  // Function to get the details for this animal.
  const fetchAnimalDetails = useCallback(async (animalID) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="$key"&equalTo="' +
          animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of animals!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      let loadedAnimal = {};

      for (const key in data) {
        loadedAnimal = {
          animalID: key,
          animalName: data[key].animalName,
          dateListed: data[key].dateListed,
          status: data[key].status,
          dateAdopted: data[key].dateAdopted,
          breed: data[key].breed,
          age: data[key].age,
          imageURL: data[key].imageURL,
          description: data[key].description,
          link: data[key].link,
        };
      }

      setAnimal(loadedAnimal);

      // Now get list of applications to adopt this animal.
      fetchApplicationsHandler(animalID);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This is where we get the list of all applicants who have applied to adopt this animal.
  const fetchApplicationsHandler = useCallback(async (animalID) => {
    // console.log("Fetching List Of Applicants...");

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applications.json?orderBy="animalID"&equalTo="' +
          animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of applications!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      loadedApplications = [];

      for (const key in data) {
        loadedApplications.push({
          key: key,
          applicantID: data[key].applicantID,
          applicantName: ".",
          newmessgs: 0,
          flaggedmessgs: 0,
          appstatus: data[key].status,
        });
      }

      // console.log("loadedApplications=", loadedApplications);

      fetchNumberOfNewMessages(authCtx.rescueID);

      // setApplications(loadedApplications);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // console.log("Running useEffect");
    fetchAnimalDetails(params.animalID);
  }, [fetchAnimalDetails]);

  // This is where we get the details of the applicant (like their name)
  const fetchApplicantDetailsHandler = useCallback(
    async (index, applicantID) => {
      // console.log(`Fetching Details Of Applicant ${applicantID}...`);

      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
            applicantID +
            '"&auth=' +
            authCtx.token
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while retrieving applicant details!"
          );
        }

        const data = await response.json();

        let applicantsName = "Unknown";
        for (const key in data) {
          // console.log(`>> Name for position ${index} =`, data[key].name);
          applicantsName = data[key].name;
        }

        setApplications((prev) => {
          const loadedApplications = [];
          prev.forEach((e1, idx) => {
            // console.log("e1.key=", e1.key);
            let name = e1.applicantName;
            if (idx === index) {
              name = applicantsName;
            }

            loadedApplications.push({
              key: e1.key,
              applicantID: e1.applicantID,
              applicantName: name,
              newmessgs: e1.newmessgs,
              flaggedmessgs: e1.flaggedmessgs,
              appstatus: e1.appstatus,
            });
          });
          return loadedApplications;
        });

        // for (const key in data) {
        //   loadedApplications.push({
        //     key: key,
        //     applicantID: data[key].applicantID,
        //   });
        // }

        // console.log("loadedApplications=", loadedApplications);

        // setApplications(loadedApplications);
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    []
  );

  // Function to get the number of new messages for each animal.
  const fetchNumberOfNewMessages = useCallback(async (rescueID1) => {
    // console.log("Fetching List Of New Messages...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/messages.json?orderBy="rescueID"&equalTo="' +
          rescueID1 +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of messages!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      // console.log("data=", data);
      for (const key in data) {
        // console.log(data[key].new, data[key].type);
        if (
          data[key].new === "Yes" &&
          data[key].type === "From-Applicant-To-Rescue" &&
          data[key].animalID === params.animalID
        ) {
          loadedApplications.forEach((e, idx) => {
            if (e.applicantID === data[key].senderUserID) {
              loadedApplications[idx].newmessgs =
                loadedApplications[idx].newmessgs + 1;
            }
          });
        }

        // console.log(`Message ${key} - flagged==${data[key].flagged}==`);

        if (
          data[key].flagged === true &&
          data[key].animalID === params.animalID
        ) {
          loadedApplications.forEach((e, idx) => {
            if (
              e.applicantID === data[key].senderUserID ||
              e.applicantID === data[key].applicantID
            ) {
              loadedApplications[idx].flaggedmessgs =
                loadedApplications[idx].flaggedmessgs + 1;
            }
          });
        }
      }

      setApplications(loadedApplications);

      // Now, for each applicant, get the applicant's name
      loadedApplications.forEach((e, index) => {
        // console.log(`Fetching applicant details for `, e);
        fetchApplicantDetailsHandler(index, e.applicantID);
        // loadedApplications[index].applicantName = fetchApplicantDetailsHandler(
        //   e.applicantID
        // );
      });
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function onApplicantDetail(applicantID) {
    //
    history.push(
      "/applicant-details/" + applicantID + "/" + params.animalID + "/none"
    );
  }

  function editAnimalInfo() {
    history.push("/add-animal/" + params.animalID);
  }

  function backToDashboard() {
    history.push("/rescue-dashboard/" + authCtx.userID);
  }

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  function copyLink() {
    setCopyButtonLabel("Copied...");
    navigator.clipboard.writeText(
      "https://www.plenty-of-pooches.org/apply-to-adopt/" + animal.animalID
    );
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button onClick={backToDashboard}>
                            Back to Dashboard
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button onClick={editAnimalInfo}>
                            Edit Animal Info
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "7vh",
                    fontSize: "4vh",
                    fontWeight: "bold",
                  }}
                >
                  Details for {animal.animalName}
                </td>
                <td style={{ width: "20%" }}>
                  <img src={animal.imageURL} style={{ maxHeight: "18vh" }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ textAlign: "center" }}>
          <label style={{ textDecoration: "underline", paddingRight: "2em" }}>
            URL:
          </label>
          <label style={{ fontWeight: "bold", paddingRight: "1em" }}>
            https://www.plenty-of-pooches.org/apply-to-adopt/{animal.animalID}
          </label>
          <button onClick={copyLink}>{copyButtonLabel}</button>
        </div>
        <div style={{ textAlign: "center", color: "purple" }}>
          <label>
            (Applicant can use this link to put in an application for this
            animal)
          </label>
        </div>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "5%" }}></td>
              <td style={{ width: "10%" }}>
                <label style={{ textDecoration: "underline" }}>Breed</label>
              </td>
              <td style={{ width: "25%" }}>
                <h4>{animal.breed}</h4>
              </td>
              <td style={{ width: "10%" }}>
                <label style={{ textDecoration: "underline" }}>Status</label>
              </td>
              <td style={{ width: "25%" }}>
                <h5>{animal.status}</h5>
              </td>
              <td style={{ width: "10%" }}>
                <label style={{ textDecoration: "underline" }}>
                  Date Adopted
                </label>
              </td>
              <td style={{ width: "15%" }}>
                <h5>{animal.dateAdopted}</h5>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <label style={{ textDecoration: "underline" }}>Age</label>
              </td>
              <td>
                <h4>{animal.age}</h4>
              </td>
              <td>
                <label style={{ textDecoration: "underline" }}>
                  Date Listed
                </label>
              </td>
              <td>
                <h5>{animal.dateListed}</h5>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "5%" }}></td>
              <td style={{ width: "10%" }}>
                <label style={{ textDecoration: "underline" }}>
                  Description
                </label>
              </td>
              <td style={{ width: "40%" }}>
                <textarea
                  defaultValue={animal.description}
                  id="description"
                  rows="4"
                  readonly="true"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    border: "1px solid lightgrey",
                  }}
                />
              </td>
              <td style={{ width: "20%" }}>
                <label style={{ textDecoration: "underline" }}>
                  Link on Pentfinder or Website
                </label>
              </td>
              <td style={{ width: "25%" }}>
                <h5>{animal.link}</h5>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <table style={{ width: "calc(100% - 1em)" }}>
          <tbody>
            <tr
              key="header"
              style={{ backgroundColor: "#38015c", color: "white" }}
            >
              {/* <td style={{ border: "1px solid black" }}>Animal ID</td> */}
              <td style={{ border: "1px solid black", width: "40%" }}>
                Applicant
              </td>
              <td style={{ border: "1px solid black", width: "20%" }}>
                Application Status
              </td>
              <td style={{ border: "1px solid black", width: "15%" }}>
                New Messages
              </td>
              <td style={{ border: "1px solid black", width: "15%" }}>
                Flagged Messages
              </td>
              <td style={{ border: "1px solid black", width: "10%" }}></td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              {applications.map((application) => (
                <tr key={application.applicantID}>
                  <td style={{ border: "1px solid black", width: "40%" }}>
                    <label
                      onClick={onApplicantDetail.bind(
                        this,
                        application.applicantID
                      )}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {application.applicantName}
                    </label>
                  </td>
                  <td style={{ border: "1px solid black", width: "20%" }}>
                    {application.appstatus}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      width: "15%",
                      backgroundColor:
                        application.newmessgs === 0 ? "white" : "pink",
                    }}
                  >
                    {application.newmessgs === 0 ? "" : application.newmessgs}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      width: "15%",
                      backgroundColor:
                        application.flaggedmessgs === 0 ? "white" : "pink",
                    }}
                  >
                    {application.flaggedmessgs === 0
                      ? ""
                      : application.flaggedmessgs}
                  </td>
                  <td style={{ border: "1px solid black", width: "10%" }}></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default AnimalDetail;
