import { useParams, useHistory } from "react-router-dom";
import { useCallback, useEffect, useState, useContext } from "react";
import LoadingSpinner from "./LoadingSpinner";
import AuthContext from "../store/auth-context";

import Button from "../UI/Button";

function ApplicantDashboard() {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [applicantUserId, setApplicantUserId] = useState("unknown");
  const [applicantName, setApplicantName] = useState();
  const [applicantAddress, setApplicantAddress] = useState();
  const [applicantPhone, setApplicantPhone] = useState();

  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let loadedApplications = [];

  // Get info about this user.
  const fetchApplicantDetails = useCallback(async () => {
    // console.log("Fetching applicant details for user", params.applicantID);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant data!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      let applicationUserId;
      for (const key in data) {
        applicationUserId = key;
        setApplicantUserId(key);
        setApplicantName(data[key].name);
        setApplicantAddress(data[key].address);
        setApplicantPhone(data[key].phone);
      }

      // console.log("loadedApplications=", loadedApplications);

      fetchApplicationsHandler(applicationUserId);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Get list of animals that this user has applied to adopt
  const fetchApplicationsHandler = useCallback(async (user) => {
    // console.log("Fetching List Of Animals for user", user);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applications.json?orderBy="applicantID"&equalTo="' +
          user +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of your applications!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      loadedApplications = [];

      for (const key in data) {
        loadedApplications.push({
          key: key,
          animalID: data[key].animalID,
          animalName: ".",
          dateListed: "",
          status: "",
          dateAdopted: "",
          breed: "",
          age: "",
          newmessgs: 0,
          appstatus: data[key].status,
        });
      }

      // console.log("loadedApplications=", loadedApplications);

      // setApplications(loadedApplications);

      fetchNumberOfNewMessages();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchApplicantDetails();
  }, [fetchApplicantDetails]);

  // This is where we get the details of the animal (like their name)
  const fetchAnimalDetailsHandler = useCallback(async (index, animalID) => {
    // console.log(`Fetching Details Of Animal ${animalID}...`);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="$key"&equalTo="' +
          animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving animal detials!"
        );
      }

      const data = await response.json();

      let animalName = "Unknown";
      let dateListed;
      let status;
      let dateAdopted;
      let breed;
      let age;
      for (const key in data) {
        // console.log(`>> Name for position ${index} =`, data[key].name);
        animalName = data[key].animalName;
        dateListed = data[key].dateListed;
        status = data[key].status;
        dateAdopted = data[key].dateAdopted;
        breed = data[key].breed;
        age = data[key].age;
      }

      setApplications((prev) => {
        const loadedApplications = [];
        prev.forEach((e1, idx) => {
          // console.log("e1.key=", e1.key);
          let animalNameT = e1.animalName;
          let dateListedT = e1.dateListed;
          let statusT = e1.status;
          let dateAdoptedT = e1.dateAdopted;
          let breedT = e1.breed;
          let ageT = e1.age;
          if (idx === index) {
            animalNameT = animalName;
            dateListedT = dateListed;
            statusT = status;
            dateAdoptedT = dateAdopted;
            breedT = breed;
            ageT = age;
          }

          loadedApplications.push({
            key: e1.key,
            animalID: e1.animalID,
            animalName: animalNameT,
            dateListed: dateListedT,
            status: statusT,
            dateAdopted: dateAdoptedT,
            breed: breedT,
            age: ageT,
            newmessgs: e1.newmessgs,
            appstatus: e1.appstatus,
          });
        });
        return loadedApplications;
      });

      // for (const key in data) {
      //   loadedApplications.push({
      //     key: key,
      //     applicantID: data[key].applicantID,
      //   });
      // }

      // console.log("loadedApplications=", loadedApplications);

      // setApplications(loadedApplications);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the number of new messages for each animal.
  const fetchNumberOfNewMessages = useCallback(async () => {
    // console.log("Fetching List Of New Messages...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/messages.json?orderBy="applicantID"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of messages!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      for (const key in data) {
        // console.log(data[key].new, data[key].type);
        if (
          data[key].new === "Yes" &&
          data[key].type === "From-Rescue-To-Applicant"
        ) {
          loadedApplications.forEach((e, idx) => {
            if (e.animalID === data[key].animalID) {
              loadedApplications[idx].newmessgs =
                loadedApplications[idx].newmessgs + 1;
            }
          });
        }
      }

      setApplications(loadedApplications);

      // Now, for each applicant, get the animal's name
      loadedApplications.forEach((e, index) => {
        // console.log(`Fetching animal details for `, e);
        fetchAnimalDetailsHandler(index, e.animalID);
        // loadedApplications[index].applicantName = fetchApplicantDetailsHandler(
        //   e.applicantID
        // );
      });
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function onAppAnimalDetail(animal) {
    history.push(
      "/app-animal-detail/" +
        params.applicantID +
        "/" +
        animal.animalID +
        "/none"
    );
  }

  function onChangePassword() {
    history.push("/change-password/applicant");
  }

  // console.log("Applications=", applications);

  function compare(a, b) {
    if (a.animalName < b.animalName) {
      return -1;
    }
    if (a.animalName > b.animalName) {
      return 1;
    }
    return 0;
  }

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  // Remove the animal with name "Unknown" as this is the dummy animal that is used when
  // the applicant submits an application to the rescue but not to adopt any specific animal.
  const tmpApplication1 = applications.filter(
    (e) => e.animalID.substring(0, 3) !== "ReS"
  );

  // Now sort the list by the animals's name
  const tmpApplications = [...tmpApplication1];
  tmpApplications.sort(compare);

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%" }}></td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "7vh",
                    fontSize: "4vh",
                    fontWeight: "bold",
                  }}
                >
                  Welcome, {applicantName}
                </td>
                <td style={{ width: "20%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button onClick={onLogout}>Logout</Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button onClick={onChangePassword}>
                            ChangePassword
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br></br>
        <h2>Your applications to adopt:</h2>
        <div>
          <table style={{ width: "calc(100% - 1em)" }}>
            <tbody>
              <tr
                key="header"
                style={{
                  backgroundColor: "#38015c",
                  color: "white",
                  height: "2em",
                }}
              >
                {/* <td style={{ border: "1px solid black" }}>Animal ID</td> */}
                <td style={{ backgroundColor: "white", width: "10%" }}></td>
                <td style={{ border: "1px solid black", width: "40%" }}>
                  Animal Name
                </td>
                <td style={{ border: "1px solid black", width: "20%" }}>
                  Application Status
                </td>
                <td style={{ border: "1px solid black", width: "15%" }}>
                  New Messages
                </td>
                <td style={{ backgroundColor: "white", width: "15%" }}></td>
              </tr>
            </tbody>
          </table>
          <div style={{ height: "60vh", overflowY: "scroll" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                {tmpApplications.map((animal) => (
                  <tr key={animal.animalID}>
                    <td style={{ width: "10%" }}></td>
                    <td
                      onClick={onAppAnimalDetail.bind(this, animal)}
                      style={{ border: "1px solid black", width: "40%" }}
                    >
                      <label
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          fontWeight: "bold",
                        }}
                      >
                        {animal.animalName}
                      </label>
                    </td>
                    <td style={{ border: "1px solid black", width: "20%" }}>
                      {animal.appstatus}
                    </td>
                    <td style={{ border: "1px solid black", width: "15%" }}>
                      {animal.newmessgs}
                    </td>
                    <td style={{ width: "15%" }}></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default ApplicantDashboard;
