import { useParams, useHistory } from "react-router-dom";
import React, {
  useRef,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import LoadingSpinner from "./LoadingSpinner";
import AuthContext from "../store/auth-context";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

import Button from "../UI/Button";

function AddAnimal(props) {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [appQuestions, setAppQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState("Text");
  const [questionReq, setQuestionReq] = useState("");

  const animalNameRef = useRef("");
  const dateListedRef = useRef("");
  const statusRef = useRef("");
  const dateAdoptedRef = useRef("");
  const breedRef = useRef("");
  const descriptionRef = useRef("");
  const linkRef = useRef("");
  const ageRef = useRef("");

  const initialAppQuestions = useRef([]);

  const currentValues = useRef({
    animalID: "",
    animalName: "",
    dateListed: "",
    status: "",
    dateAdopted: "",
    breed: "",
    age: "",
    imageURL: "",
    description: "",
    link: "",
  });

  function compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  // Function to get the details for this animal.
  const fetchAnimalInfo = useCallback(async (animalID) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="$key"&equalTo="' +
          animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of animals!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      for (const key in data) {
        currentValues.current = {
          animalID: key,
          animalName: data[key].animalName,
          dateListed: data[key].dateListed,
          status: data[key].status,
          dateAdopted: data[key].dateAdopted,
          breed: data[key].breed,
          age: data[key].age,
          description: data[key].description,
          link: data[key].link,
        };
        setImageUrl(data[key].imageURL);
      }

      // Now get list of application questions for this animal.
      fetchAnimalApplicationQuestions(animalID);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the list of animal's applicaton questions.
  const fetchAnimalApplicationQuestions = useCallback(async (animalID) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals_application.json?orderBy="animalID"&equalTo="' +
          animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of animal's application questions!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const applicationQuestions = [];

      for (const key in data) {
        if (data[key].deleted === false) {
          applicationQuestions.push({
            questionID: key,
            field_name: data[key].field_name,
            required: data[key].required,
            type: data[key].type,
            order: data[key].order,
          });
        }
      }

      applicationQuestions.sort(compare);

      console.log(
        "Animal-Specific Application Questions",
        applicationQuestions
      );

      setAppQuestions(applicationQuestions);

      initialAppQuestions.current = [...applicationQuestions];
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (params.animalID !== "none") {
      fetchAnimalInfo(params.animalID);
    }
  }, []);

  // This function submits all the data except the questions when adding an animal
  const changeAnimalHandler = useCallback(async (animal, tempQuestions) => {
    // console.log("changeAnimalHandler - tempQuestions=", tempQuestions);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/animals/" +
          params.animalID +
          ".json?auth=" +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify(animal),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing animal to database!"
        );
      }

      const data = await response.json();

      const animalID = params.animalID;

      tempQuestions.forEach((e, indx) => {
        // console.log("Calling addQuestion for question ", e);
        if (e.questionID === "") {
          // User has added a new question
          addQuestionHandler(e, animalID, indx);
        } else {
          // User has changed an existing question
          changeExistingQuestion(e, animalID, e.questionID, indx);
        }
      });

      initialAppQuestions.current.forEach((e) => {
        let deleted = true;
        tempQuestions.forEach((q) => {
          if (q.questionID === e.questionID) {
            deleted = false;
          }
        });

        if (deleted) {
          console.log(`Question ${e.questionID} has been deleted`);
          deleteExistingQuestion(e.questionID);
        }
      });

      // alert("Animal as been succesfully updated!");

      closeAddAnimalPage();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This function changes an existing animal's questions.
  const changeExistingQuestion = useCallback(
    async (question, animalID, questionID, order) => {
      // console.log("animalID=", animalID);
      // console.log("question=", question);
      // console.log("questionID", questionID);

      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://plenty-of-pooches-default-rtdb.firebaseio.com/animals_application/" +
            questionID +
            ".json?auth=" +
            authCtx.token,
          {
            method: "PATCH",
            body: JSON.stringify({
              changedBy: authCtx.userID,
              animalID: animalID,
              field_name: question.field_name,
              type: question.type,
              required: question.required,
              order: order,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while writing animal application question to database!"
          );
        }

        const data = await response.json();

        // closeAddAnimalPage();
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    []
  );

  // This function changes an existing the questions.
  const deleteExistingQuestion = useCallback(async (questionID) => {
    // console.log("animalID=", animalID);
    // console.log("question=", question);
    console.log("deleteExistingQuestion - questionID", questionID);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/animals_application/" +
          questionID +
          ".json?auth=" +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify({
            changeBy: authCtx.userID,
            deleted: true,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing animal application question to database!"
        );
      }

      const data = await response.json();

      console.log("deleteExistingQuestion - data=", data);

      // closeAddAnimalPage();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This function submits the questions.
  const addQuestionHandler = useCallback(async (question, animalID, order) => {
    console.log("animalID=", animalID);
    console.log("question=", question);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/animals_application.json?auth=" +
          authCtx.token,
        {
          method: "POST",
          body: JSON.stringify({
            changedBy: authCtx.userID,
            animalID: animalID,
            field_name: question.field_name,
            type: question.type,
            required: question.required,
            deleted: false,
            order: order,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing animal application question to database!"
        );
      }

      const data = await response.json();

      // closeAddAnimalPage();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This function submits all the data except the questions when changing an existing animal.
  const addAnimalHandler = useCallback(async (animal, tempQuestions) => {
    console.log("addAnimalHandler - tempQuestions=", tempQuestions);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?auth=" +
          authCtx.token,
        {
          method: "POST",
          body: JSON.stringify(animal),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing animal to database!"
        );
      }

      const data = await response.json();

      console.log("Response from adding animal =", data);

      const animalID = data.name;

      tempQuestions.forEach((e, indx) => {
        console.log("Calling addQuestion for question ", e);
        addQuestionHandler(e, animalID, indx);
      });

      alert(
        "URL To Apply To Adopt:  https://www.plenty-of-pooches.org/apply-to-adopt/" +
          animalID
      );

      closeAddAnimalPage();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function submitHandler(event) {
    // console.log("running submitHandler now...");
    const tempQuestions = [...appQuestions];
    // console.log("submitHandler - tempQuestions=", tempQuestions);

    event.preventDefault();

    if (
      animalNameRef.current.value === "" ||
      dateListedRef.current.value === "" ||
      statusRef.current.value === "" ||
      breedRef.current.value === "" ||
      ageRef.current.value === ""
    ) {
      alert(" Must fill in all the required fields.");
      return;
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    const animal = {
      rescueID: authCtx.rescueID,
      animalName: animalNameRef.current.value,
      dateListed: dateListedRef.current.value,
      status: statusRef.current.value,
      dateAdopted: dateAdoptedRef.current.value,
      breed: breedRef.current.value,
      age: ageRef.current.value,
      description: descriptionRef.current.value,
      link: linkRef.current.value,
      dateAdded: today,
      imageURL: imageUrl,
      changedBy: authCtx.userID,
    };

    if (params.animalID === "none") {
      addAnimalHandler(animal, tempQuestions);
    } else {
      changeAnimalHandler(animal, tempQuestions);
    }
  }

  const uploadImage = (event) => {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_) => {
      let files = Array.from(input.files);
      // console.log("files[0] = ", files[0]);
      // console.log("files[0].name =", files[0].name);

      setImageUpload(files[0]);
      const image2Upload = files[0];

      if (image2Upload == null) return;

      const imageRef = ref(storage, `images/${v4()}`);
      uploadBytes(imageRef, image2Upload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          // alert(`Image Uploaded URL= ${url}`);
          setImageUrl(url);
        });
      });
    };
    input.click();
  };

  function closeAddAnimalPage() {
    // if (params.animalID === "none") {
    //   history.replace("/rescue-dashboard/" + authCtx.userID);
    // } else {
    //   history.replace("/animal_details/" + params.animalID);
    // }
    window.history.go(-1);
  }

  function addAnimalApplicationQuestion() {
    if (question === "") {
      alert("Question cannot be blank");
    } else {
      let alreadyInList = false;
      appQuestions.forEach((e) => {
        if (e.field_name === question) {
          alreadyInList = true;
        }
      });
      if (alreadyInList) {
        alert("Cannot add the same question twice.");
      } else {
        setAppQuestions((prev) => {
          const tmpAppQuestions = [...prev];
          tmpAppQuestions.push({
            questionID: "",
            field_name: question,
            type: questionType,
            required: questionReq,
          });
          return [...tmpAppQuestions];
        });
        setQuestion("");
        setQuestionType("Text");
        setQuestionReq("");
      }
    }
  }

  function deleteAnimalApplicationQuestion(position) {
    setAppQuestions((prev) => {
      const tmpAppQuestions = [...prev];
      tmpAppQuestions.splice(position, 1);
      return [...tmpAppQuestions];
    });
  }

  function questionEventHandler(event) {
    setQuestion(event.target.value);
  }

  function questionTypeEventHandler(event) {
    setQuestionType(event.target.value);
  }

  function questionReqEventHandler(event) {
    if (event.target.checked) {
      setQuestionReq(true);
    } else {
      setQuestionReq(false);
    }
  }

  // console.log("currentValues.current=", currentValues.current);
  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button onClick={closeAddAnimalPage}>CANCEL</Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Button onClick={submitHandler}>
                              {params.animalID === "none" ? "Save" : "Save"}
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: "7vh",
                      fontSize: "4vh",
                      fontWeight: "bold",
                    }}
                  >
                    Add Animal
                  </td>
                  <td style={{ width: "30%" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {imageUrl !== null && (
                              <img
                                src={imageUrl}
                                style={{ maxHeight: "18vh" }}
                              />
                            )}
                          </td>
                          <td>
                            <button type="button" onClick={uploadImage}>
                              Add/Change Image
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br></br>
          <br></br>
          <label
            htmlFor="animalName"
            style={{ textDecoration: "underline", paddingRight: "2em" }}
          >
            Animal Name
          </label>
          <input
            type="text"
            defaultValue={currentValues.current.animalName}
            id="animalName"
            ref={animalNameRef}
            style={{
              fontWeight: "bold",
              border: "1px solid lightgrey",
            }}
          ></input>
          <br></br>
          <br></br>
          <table
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: "5%" }}></td>
                <td style={{ width: "10%" }}>
                  <label
                    htmlFor="breed"
                    style={{ textDecoration: "underline" }}
                  >
                    Breed
                  </label>
                </td>
                <td style={{ width: "25%" }}>
                  <input
                    type="text"
                    defaultValue={currentValues.current.breed}
                    id="breed"
                    ref={breedRef}
                    style={{
                      fontWeight: "bold",
                      border: "1px solid lightgrey",
                    }}
                  />
                </td>
                <td style={{ width: "10%" }}>
                  <label
                    htmlFor="status"
                    style={{ textDecoration: "underline" }}
                  >
                    Status
                  </label>
                </td>
                <td style={{ width: "25%" }}>
                  <select
                    type="text"
                    defaultValue={currentValues.current.status}
                    id="status"
                    ref={statusRef}
                    style={{
                      fontWeight: "bold",
                      border: "1px solid lightgrey",
                    }}
                  >
                    <option value="Available">Available</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Adoption Pending">Adoption Pending</option>
                    <option value="Adopted">Adopted</option>
                  </select>
                </td>
                <td style={{ width: "10%" }}>
                  <label
                    htmlFor="dateAdopted"
                    style={{ textDecoration: "underline" }}
                  >
                    Date Adopted
                  </label>
                </td>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    defaultValue={currentValues.current.dateAdopted}
                    id="dateAdopted"
                    ref={dateAdoptedRef}
                    style={{
                      fontWeight: "bold",
                      border: "1px solid lightgrey",
                    }}
                  />
                </td>
              </tr>
              {/* <tr style={{ height: "2em" }}></tr> */}
              <tr>
                <td></td>
                <td>
                  <label htmlFor="age" style={{ textDecoration: "underline" }}>
                    Age
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={currentValues.current.age}
                    id="age"
                    ref={ageRef}
                    style={{
                      fontWeight: "bold",
                      border: "1px solid lightgrey",
                    }}
                  />
                </td>
                <td>
                  <label
                    htmlFor="dateListed"
                    style={{ textDecoration: "underline" }}
                  >
                    Date Listed
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={currentValues.current.dateListed}
                    id="dateListed"
                    ref={dateListedRef}
                    style={{
                      fontWeight: "bold",
                      border: "1px solid lightgrey",
                    }}
                  />
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <table
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: "5%" }}></td>
                <td style={{ width: "10%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <label
                            htmlFor="breed"
                            style={{ textDecoration: "underline" }}
                          >
                            Description
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label style={{ fontSize: "12px", color: "grey" }}>
                            (Only rescue sees this)
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ width: "40%" }}>
                  <textarea
                    // type="text"
                    defaultValue={currentValues.current.description}
                    id="description"
                    ref={descriptionRef}
                    rows="4"
                    style={{
                      width: "90%",
                      fontWeight: "bold",
                      border: "1px solid lightgrey",
                    }}
                  />
                </td>
                <td style={{ width: "20%" }}>
                  <label
                    htmlFor="dateAdopted"
                    style={{ textDecoration: "underline" }}
                  >
                    Link on Pentfinder or Website
                  </label>
                </td>
                <td style={{ width: "25%" }}>
                  <input
                    type="text"
                    defaultValue={currentValues.current.link}
                    id="link"
                    ref={linkRef}
                    style={{
                      width: "90%",
                      fontWeight: "bold",
                      border: "1px solid lightgrey",
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <table style={{ width: "98vw", textAlign: "left" }}>
            <tbody>
              <tr>
                <td style={{ width: "75%" }}>
                  <label style={{ fontWeight: "bold" }}>New Question</label>
                </td>
                <td style={{ width: "10%" }}>
                  <label style={{ fontWeight: "bold" }}>Type</label>
                </td>
                <td style={{ width: "10%" }}>
                  <label style={{ fontWeight: "bold" }}>Required?</label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input
                    style={{ width: "95%" }}
                    value={question}
                    onChange={questionEventHandler}
                  />
                </td>
                <td>
                  <select
                    value={questionType}
                    onChange={questionTypeEventHandler}
                    style={{ width: "90%" }}
                  >
                    <option value="Text">Text</option>
                    <option value="Number">Number</option>
                    <option value="Yes/No">Yes/No</option>
                  </select>
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={questionReq ? "checked" : ""}
                    onChange={questionReqEventHandler}
                  />
                </td>
                <td>
                  <button onClick={addAnimalApplicationQuestion}>+</button>
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <table style={{ width: "98vw", textAlign: "left" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: "75%",
                    backgroundColor: "#38015c",
                    textAlign: "left",
                  }}
                >
                  <label style={{ color: "white" }}>Application Question</label>
                </td>
                <td
                  style={{
                    width: "10%",
                    backgroundColor: "#38015c",
                    textAlign: "left",
                  }}
                >
                  <label style={{ color: "white" }}>Type</label>
                </td>
                <td
                  style={{
                    width: "10%",
                    backgroundColor: "#38015c",
                    textAlign: "left",
                  }}
                >
                  <label style={{ color: "white" }}>Required?</label>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div
            style={{
              height: "39vh",
              overflowY: "scroll",
              border: "1px solid black",
            }}
          >
            <table style={{ width: "98vw", textAlign: "left" }}>
              <tbody>
                {appQuestions.map((question, index) => (
                  <tr key={question.field_name}>
                    <td style={{ width: "75%" }}>
                      <input
                        style={{ width: "95%" }}
                        defaultValue={question.field_name}
                        disabled
                      />
                    </td>
                    <td style={{ width: "10%" }}>
                      <input
                        defaultValue={question.type}
                        disabled
                        style={{ width: "90%" }}
                      />
                    </td>
                    <td style={{ width: "10%" }}>
                      <input
                        type="checkbox"
                        checked={question.required ? "checked" : ""}
                        disabled
                      />
                    </td>
                    <td>
                      <button
                        onClick={deleteAnimalApplicationQuestion.bind(
                          this,
                          index
                        )}
                      >
                        -
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default AddAnimal;
