import { useParams, useHistory } from "react-router-dom";
import { useEffect, useCallback, useState, useContext, useRef } from "react";
import LoadingSpinner from "./LoadingSpinner";
import AuthContext from "../store/auth-context";

import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";

import Button from "../UI/Button";

function CompleteApplication() {
  const authCtx = useContext(AuthContext);

  const messagesCollectionRef = collection(db, "messages");

  const params = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [forceReload, setForcReload] = useState(true);

  const [genericApplicationQuestions, setGenericApplicationQuestions] =
    useState([]);
  const [rescuesApplicationQuestions, setRescuesApplicationQuestions] =
    useState([]);
  const [animalApplicationQuestions, setAnimalApplicationQuestions] = useState(
    []
  );

  const [rescueOrgName, setRescueOrgName] = useState("");
  const [rescueOrgImage, setRescueOrgImage] = useState("");
  const [useStandardApplication, setUseStandardApplication] = useState(false);

  const [attachmentsArray, setAttachmentsArray] = useState([]);

  const applicant = useRef({});

  const genericInputRef = useRef([]);
  const rescueInputRef = useRef([]);
  const animalInputRef = useRef([]);

  const nonStateGenericApplicationQuestions = useRef([]);
  const nonStateRescueApplicationQuestions = useRef([]);
  const nonStateAnimalApplicationQuestions = useRef([]);

  const [animalName, setAnimalName] = useState("");
  const [animalImageUrl, setAnimalImageUrl] = useState("");
  const [animalLink, setAnimalLink] = useState("");

  const rescueDetails = useRef({
    rescueName: "",
    rescueEmail: "",
  });

  function compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  // This function is used to check if this applicant has already applied to adopt this animal.
  // This is done before the user is asked to fill in the application questios.
  const hasAlreadyAppliedToAdopt = useCallback(async () => {
    // console.log("Fetching List Of Applicants...");

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applications.json?orderBy="animalID"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of applications!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      let hasPreviouslyApplied = false;

      for (const key in data) {
        if (data[key].applicantID === authCtx.userID) {
          hasPreviouslyApplied = true;
        }
      }

      // console.log("hasPreviouslyApplied", hasPreviouslyApplied);

      if (hasPreviouslyApplied) {
        if (params.animalID.substring(0, 3) !== "ReS") {
          alert("You have already applied to adopt this animal.");
        } else {
          alert(`You have already submitted an application to this rescue!`);
        }
        history.replace("/applicant-dashboard/" + authCtx.userID);
      } else {
        fetchAnimalNameHandler();
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This function is used to double check if this applicant has already applied to adopt this animal.
  // This is done after the user has filled in the application questions and the responses have been
  // witten to the database (for some reason, if we do this check before the responses are written to
  // the database, it causes all sorts of problems).
  const hasAlreadyAppliedToAdoptDoubleCheck = useCallback(async () => {
    // console.log("Fetching List Of Applicants...");

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applications.json?orderBy="animalID"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of applications!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      let hasPreviouslyApplied = false;

      for (const key in data) {
        if (data[key].applicantID === authCtx.userID) {
          hasPreviouslyApplied = true;
        }
      }

      // console.log("hasPreviouslyApplied", hasPreviouslyApplied);

      if (hasPreviouslyApplied) {
        setError("You have previously applied to adopt this animal.");
      } else {
        let application = {
          animalID: params.animalID,
          applicantID: authCtx.userID,
          rescueID: params.rescueID,
        };

        addApplicationlHandler(application);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Create message in special table that is used to tell SendGrid to send an e-mail.
  const createFirestoreMessage = async (messageObject, messageId) => {
    // console.log(
    //   "GOT HERE - rescueDetails.current.rescueEmail=",
    //   rescueDetails.current.rescueEmail
    // );
    // console.log(
    //   "GOT HERE - rescueDetails.current.rescueName=",
    //   rescueDetails.current.rescueName
    // );
    await addDoc(messagesCollectionRef, {
      email: rescueDetails.current.rescueEmail,
      name: rescueDetails.current.rescueName,
      animalid: params.animalID,
      messageid: messageId,
      applicantid: authCtx.userID,
      pagename: "applicant-details",
    });

    alert("Application to adopt succesfully submitted!");

    history.replace("/applicant-dashboard/" + authCtx.userID);
  };

  // This is where we get the details of the rescue org
  const fetchRescueDetails = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues.json?orderBy="$key"&equalTo="' +
          params.rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving rescue's details!"
        );
      }

      const data = await response.json();

      // console.log("fetchRescueDetails - data=", data);

      for (const key in data) {
        rescueDetails.current = {
          rescueName: data[key].name,
          rescueEmail: data[key].email,
        };
        setRescueOrgName(data[key].name);
        setRescueOrgImage(data[key].imageURL);
        setUseStandardApplication(data[key].usestandardapplication);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch the details of this applicant
  const fetchApplicantDetailsHandler = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
          authCtx.userID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant details!"
        );
      }

      const data = await response.json();

      // console.log("fetchApplicantDetailsHandler >> data=", data);

      let applicantTemp = {};

      for (const key in data) {
        applicantTemp = {
          key: key,
          address: data[key].address,
          name: data[key].name,
          phone: data[key].phone,
          email: data[key].email,
        };
      }

      // console.log("applicantTemp = ", applicantTemp);

      applicant.current = { ...applicantTemp };
      // console.log("applicant.current=", applicant.current);

      // Now get the details for this rescue that will be needed to create a new message and e-mail.
      fetchRescueDetails();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch animal's name to display
  const fetchAnimalNameHandler = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals.json?orderBy="$key"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error("Something went wrong while retrieving animal's name!");
      }

      const data = await response.json();

      // console.log(">> fetchAnimalNameHandler - data=", data);

      for (const key in data) {
        setAnimalName(data[key].animalName);
        // console.log("image URL=", data[key].imageURL);
        setAnimalImageUrl(data[key].imageURL);
        setAnimalLink(data[key].link);
      }

      // Now call the function that will get the generic application questions.
      fetchGenericApplicationQuestions();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the list of generic applicaton questions.
  const fetchGenericApplicationQuestions = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/generic_application.json?&auth=" +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of standard application questions!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const applicationQuestions = [];

      for (const key in data) {
        if (data[key].deleted === false) {
          applicationQuestions.push({
            questionID: key,
            field_name: data[key].field_name,
            required: data[key].required,
            type: data[key].type,
            highlight: false,
            response: "",
            responseID: "",
            order: data[key].order,
          });
        }
      }
      applicationQuestions.sort(compare);

      // console.log("Standard Applicatin Questions", applicationQuestions);

      nonStateGenericApplicationQuestions.current = applicationQuestions;

      // Now call the function to get the rescue-specific questions.
      fetchRescuesApplicationQuestions();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the list of rescue's applicaton questions.
  const fetchRescuesApplicationQuestions = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues_application.json?orderBy="rescueID"&equalTo="' +
          params.rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of rescue application questions!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const applicationQuestions = [];

      for (const key in data) {
        if (data[key].deleted === false) {
          applicationQuestions.push({
            questionID: key,
            field_name: data[key].field_name,
            required: data[key].required,
            type: data[key].type,
            highlight: false,
            response: "",
            responseID: "",
            order: data[key].order,
          });
        }
      }

      applicationQuestions.sort(compare);

      // console.log(
      //   "Rescue-Specific Application Questions",
      //   applicationQuestions
      // );

      nonStateRescueApplicationQuestions.current = applicationQuestions;

      // Now call the function to get the animal-specific questions.
      fetchAnimalApplicationQuestions();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the list of animal's applicaton questions.
  const fetchAnimalApplicationQuestions = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/animals_application.json?orderBy="animalID"&equalTo="' +
          params.animalID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of animal's application questions!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const applicationQuestions = [];

      for (const key in data) {
        if (data[key].deleted === false) {
          applicationQuestions.push({
            questionID: key,
            field_name: data[key].field_name,
            required: data[key].required,
            type: data[key].type,
            highlight: false,
            response: "",
            responseID: "",
            order: data[key].order,
          });
        }
      }

      applicationQuestions.sort(compare);

      // console.log(
      //   "Animal-Specific Application Questions",
      //   applicationQuestions
      // );

      nonStateAnimalApplicationQuestions.current = applicationQuestions;

      // Now call the function to get this applicants previous responses to the questions.
      fetchResponses();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to get the previous responses submitted by this applicant.
  const fetchResponses = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    // console.log("  tempRescueID=", tempRescueID);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/responses.json?orderBy="applicantID"&equalTo="' +
          authCtx.userID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of previous responses!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      const responses = [];

      for (const key in data) {
        responses.push({
          responseID: key,
          animalID: data[key].animalID,
          applicantID: data[key].applicantID,
          field_name: data[key].field_name,
          questionID: data[key].questionID,
          rescueID: data[key].rescueID,
          response: data[key].response,
          type: data[key].type,
        });
      }

      // console.log("responses=", responses);

      // Now update the generic questions with the previous responses.
      nonStateGenericApplicationQuestions.current.forEach((e, idx) => {
        responses.forEach((r) => {
          if (e.questionID === r.questionID) {
            nonStateGenericApplicationQuestions.current[idx].response =
              r.response;
            nonStateGenericApplicationQuestions.current[idx].responseID =
              r.responseID;
          }
        });
      });

      // console.log(
      //   "nonStateGenericApplicationQuestions.current=",
      //   nonStateGenericApplicationQuestions.current
      // );

      setGenericApplicationQuestions(
        nonStateGenericApplicationQuestions.current
      );

      // Now update the rescue-specific questions with the previous responses.
      nonStateRescueApplicationQuestions.current.forEach((e, idx) => {
        responses.forEach((r) => {
          if (e.questionID === r.questionID) {
            nonStateRescueApplicationQuestions.current[idx].response =
              r.response;
            nonStateRescueApplicationQuestions.current[idx].responseID =
              r.responseID;
          }
        });
      });

      // console.log(
      //   "nonStateRescueApplicationQuestions.current=",
      //   nonStateRescueApplicationQuestions.current
      // );

      setRescuesApplicationQuestions(
        nonStateRescueApplicationQuestions.current
      );

      // Now update the animal-specific questions with the previous responses.
      nonStateAnimalApplicationQuestions.current.forEach((e, idx) => {
        responses.forEach((r) => {
          if (e.questionID === r.questionID) {
            nonStateAnimalApplicationQuestions.current[idx].response =
              r.response;
            nonStateAnimalApplicationQuestions.current[idx].responseID =
              r.responseID;
          }
        });
      });

      // console.log(
      //   "nonStateAnimalApplicationQuestions.current=",
      //   nonStateAnimalApplicationQuestions.current
      // );

      setAnimalApplicationQuestions(nonStateAnimalApplicationQuestions.current);

      fetchApplicantDetailsHandler();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    hasAlreadyAppliedToAdopt();
  }, [hasAlreadyAppliedToAdopt]);

  // This is used to create a new response record for a question the user
  // has not previously responded to.
  const submitResponsesHandler = useCallback(async (responses) => {
    // console.log("authCtx=", authCtx);
    // setPageShowing("RescueDashboard");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/responses.json?auth=" +
          authCtx.token,
        {
          method: "POST",
          body: JSON.stringify(responses),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing responses to the database!"
        );
      }

      const data = await response.json();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This is used to update the response to a question that the applicant has prevoiusly
  // answered.
  const updateApplicantResponse = useCallback(
    async (responseID, responseText) => {
      // console.log(
      //   `Running updateApplicantResponse - responseID=${responseID} - responseText=${responseText}`
      // );
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://plenty-of-pooches-default-rtdb.firebaseio.com/responses/" +
            responseID +
            ".json?auth=" +
            authCtx.token,
          {
            method: "PATCH",
            body: JSON.stringify({ response: responseText }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while writing application to database!"
          );
        }

        const data = await response.json();
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    []
  );

  function submitHandler(event) {
    event.preventDefault();

    console.log("GOT HERE");

    submitApplicationHandler();
  }

  function submitApplicationHandler() {
    let formComplete = true;

    if (useStandardApplication) {
      genericApplicationQuestions.forEach((e, index) => {
        if (
          genericInputRef.current[index].value === "" &&
          e.required === true
        ) {
          // This is a required field and it is missing a value.
          e.highlight = true;
          formComplete = false;
        } else {
          e.highlight = false;
        }
      });
    }

    rescuesApplicationQuestions.forEach((e, index) => {
      if (rescueInputRef.current[index].value === "" && e.required === true) {
        // This is a required field and it is missing a value.
        e.highlight = true;
        formComplete = false;
      } else {
        e.highlight = false;
      }
    });

    animalApplicationQuestions.forEach((e, index) => {
      if (animalInputRef.current[index].value === "" && e.required === true) {
        // This is a required field and it is missing a value.
        e.highlight = true;
        formComplete = false;
      } else {
        e.highlight = false;
      }
    });

    if (formComplete === false) {
      alert("One or more required fields has not been populated!!");
      setForcReload((prev) => !prev);
    } else {
      ///////////////////////////////////////
      // Write the answers to the databse. //
      ///////////////////////////////////////

      // console.log("genericApplicationQuestions=", genericApplicationQuestions);
      if (useStandardApplication) {
        genericApplicationQuestions.forEach((e, index) => {
          if (e.responseID === "") {
            // This is a response to a question the applicant has not responded to before.
            let response = {
              applicantID: authCtx.userID,
              type: "Generic",
              questionID: e.questionID,
              field_name: e.field_name,
              response: genericInputRef.current[index].value,
              rescueID: "",
              animalID: "",
            };

            // console.log("response=", response);

            // Call the function that writes the responses to the database
            submitResponsesHandler(response);
          } else {
            if (genericInputRef.current[index].value !== e.response) {
              // This is updating a response to a question that the user has responded to before.
              updateApplicantResponse(
                e.responseID,
                genericInputRef.current[index].value
              );
            }
          }
        });
      }

      rescuesApplicationQuestions.forEach((e, index) => {
        if (e.responseID === "") {
          // This is a response to a question the applicant has not responded to before.
          let response = {
            applicantID: authCtx.userID,
            type: "Rescue",
            questionID: e.questionID,
            field_name: e.field_name,
            response: rescueInputRef.current[index].value,
            rescueID: params.rescueID,
            animalID: "",
          };

          // console.log("response=", response);

          // Call the function that writes the responses to the database
          submitResponsesHandler(response);
        } else {
          if (rescueInputRef.current[index].value !== e.response) {
            // This is updating a response to a question that the user has responded to before.
            updateApplicantResponse(
              e.responseID,
              rescueInputRef.current[index].value
            );
          }
        }
      });

      animalApplicationQuestions.forEach((e, index) => {
        if (e.responseID === "") {
          // This is a response to a question the applicant has not responded to before.
          let response = {
            applicantID: authCtx.userID,
            type: "Animal",
            questionID: e.questionID,
            field_name: e.field_name,
            response: animalInputRef.current[index].value,
            rescueID: "",
            animalID: params.animalID,
          };

          // console.log("response=", response);

          // Call the function that writes the responses to the database
          submitResponsesHandler(response);
        } else {
          if (animalInputRef.current[index].value !== e.response) {
            // This is updating a response to a question that the user has responded to before.
            updateApplicantResponse(
              e.responseID,
              animalInputRef.current[index].value
            );
          }
        }
      });

      // Now do a double check to make sure there isn't already an application for this
      // animal from this adopter.
      hasAlreadyAppliedToAdoptDoubleCheck();
    }
  }

  // This creates a record in the "applications" collection to record that this use has applied to
  // adopt this animal.
  const addApplicationlHandler = useCallback(async (application) => {
    // console.log("authCtx=", authCtx);
    // setPageShowing("RescueDashboard");

    // console.log("application=", application);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/applications.json?auth=" +
          authCtx.token,
        {
          method: "POST",
          body: JSON.stringify(application),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing application to database!"
        );
      }

      const data = await response.json();

      sendMessage();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function sendMessage() {
    // console.log("animalID=", animalID);
    // console.log("rescueID=", authCtx.rescueID);
    // console.log("applicantID=", applicantID);
    // console.log("rescueUserID=", authCtx.userID);
    // console.log("message=", messageInputRef.current.value);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    var hh = String(today.getHours()).padStart(2, "0");
    var mn = String(today.getMinutes()).padStart(2, "0");
    var ss = String(today.getSeconds()).padStart(2, "0");

    today = mm + "/" + dd + "/" + yyyy;
    const time = hh + ":" + mn + ":" + ss;

    const messageObject = {
      animalID: params.animalID,
      rescueID: params.rescueID,
      applicantID: authCtx.userID,
      senderUserID: authCtx.userID,
      senderUserName: applicant.current.name,
      type: "From-Applicant-To-Rescue",
      date: today,
      time: time,
      read: "No",
      new: "Yes",
      message: "New application to adopt has been submitted.",
      attachmentsArray: attachmentsArray,
      changedBy: authCtx.userID,
    };

    sendMesssageHandler(messageObject);
  }

  // This is used to send a new message.
  const sendMesssageHandler = useCallback(
    async (messageObject) => {
      // console.log("authCtx=", authCtx);
      // setPageShowing("RescueDashboard");
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://plenty-of-pooches-default-rtdb.firebaseio.com/messages.json?auth=" +
            authCtx.token,
          {
            method: "POST",
            body: JSON.stringify(messageObject),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while writing message to the database!"
          );
        }

        const data = await response.json();

        // console.log("Response data.name = ", data.name);

        //addMessageToList(messageObject);

        createFirestoreMessage(messageObject, data.name);
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    [authCtx.token, createFirestoreMessage]
  );

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  console.log(
    "params.animalID.substring(0, 3)=",
    params.animalID.substring(0, 3)
  );
  // console.log("useStandardApplication", useStandardApplication);
  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <button onClick={onLogout}>Login Again</button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "25%", textAlign: "left" }}>
                  <img
                    src={animalImageUrl}
                    style={{ maxHeight: "18vh", border: "2px solid black" }}
                  />
                </td>
                <td style={{ width: "50%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "7vh",
                      fontSize: "4vh",
                      fontWeight: "bold",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr>
                          {params.animalID.substring(0, 3) !== "ReS" && (
                            <td>
                              <label> Application to Adopt </label>
                              <label>'{animalName}'</label>
                            </td>
                          )}
                          {params.animalID.substring(0, 3) === "ReS" && (
                            <td>
                              <label>
                                Fill out application form for "{rescueOrgName}"
                              </label>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>
                            <a
                              href={animalLink}
                              target="_blank"
                              style={{ fontSize: "14px" }}
                            >
                              {animalLink}
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td style={{ width: "25%", textAlign: "right" }}>
                  <img
                    src={rescueOrgImage}
                    style={{ maxHeight: "18vh", border: "2px solid black" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ height: "78vh", overflowY: "scroll" }}>
          <form onSubmit={submitHandler}>
            {useStandardApplication === true && (
              <>
                <h1
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    paddingLeft: 20,
                  }}
                >
                  Standard Application Questions:
                </h1>
                {genericApplicationQuestions.map((question, idx) => (
                  <div
                    key={question.questionID}
                    style={{
                      display: "flex",
                      padding: "10px",
                      border: "1px solid black",
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <div
                      style={{
                        flex: 4,
                        borderRight: "1px solid black",
                      }}
                    >
                      <label style={{ fontWeight: "bold" }}>
                        {question.field_name}{" "}
                      </label>
                    </div>
                    <div style={{ flex: 8 }}>
                      {question.type === "Text" && (
                        <textarea
                          ref={(el) => (genericInputRef.current[idx] = el)}
                          // type={question.type === "Text" ? "text" : "number"}
                          defaultValue={question.response}
                          style={{
                            border: "none",
                            fontWeight: "bold",
                            width: "98%",
                            resize: "none",
                            marginLeft: "5px",
                            // marginRight: "5px",
                          }}
                          wrap="soft"
                        />
                      )}
                      {question.type === "Number" && (
                        <input
                          ref={(el) => (genericInputRef.current[idx] = el)}
                          type="number"
                          defaultValue={question.response}
                          style={{
                            border: "none",
                            // borderBottom: "1px solid black",
                            fontWeight: "bold",
                            marginLeft: "5px",
                          }}
                        />
                      )}
                      {question.type === "Yes/No" && (
                        <select
                          type="text"
                          ref={(el) => (genericInputRef.current[idx] = el)}
                          defaultValue={question.response}
                          style={{
                            border: "none",
                            // borderBottom: "1px solid black",
                            fontWeight: "bold",
                            marginLeft: "5px",
                          }}
                        >
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      {question.required && (
                        <label
                          style={{
                            color: question.highlight ? "red" : "black",
                            paddingLeft: "10px",
                          }}
                        >
                          (required)
                        </label>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            <br></br>
            <h1
              style={{
                textDecoration: "underline",
                fontStyle: "italic",
                paddingLeft: 20,
              }}
            >
              Rescue-specific Application Questions for '
              {rescueDetails.current.rescueName}':
            </h1>
            {rescuesApplicationQuestions.map((question, idx) => (
              <div
                key={question.questionID}
                style={{
                  display: "flex",
                  padding: "10px",
                  border: "1px solid black",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    flex: 4,
                    borderRight: "1px solid black",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>
                    {question.field_name}{" "}
                  </label>
                </div>
                <div style={{ flex: 8 }}>
                  {question.type === "Text" && (
                    <textarea
                      ref={(el) => (rescueInputRef.current[idx] = el)}
                      // type={question.type === "Text" ? "text" : "number"}
                      defaultValue={question.response}
                      style={{
                        border: "none",
                        fontWeight: "bold",
                        width: "98%",
                        resize: "none",
                        marginLeft: "5px",
                        // marginRight: "5px",
                      }}
                      wrap="soft"
                    />
                  )}
                  {question.type === "Number" && (
                    <input
                      ref={(el) => (rescueInputRef.current[idx] = el)}
                      type="number"
                      defaultValue={question.response}
                      style={{
                        border: "none",
                        // borderBottom: "1px solid black",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                  {question.type === "Yes/No" && (
                    <select
                      type="text"
                      ref={(el) => (rescueInputRef.current[idx] = el)}
                      defaultValue={question.response}
                      style={{
                        border: "none",
                        // borderBottom: "1px solid black",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    >
                      <option value=""></option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  {question.required && (
                    <label
                      style={{
                        color: question.highlight ? "red" : "black",
                        paddingLeft: "10px",
                      }}
                    >
                      (required)
                    </label>
                  )}
                </div>
              </div>
            ))}
            <br></br>
            {params.animalID.substring(0, 3) !== "ReS" && (
              <>
                <h1
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    paddingLeft: 20,
                  }}
                >
                  Animal-specific Application Questions:
                </h1>
                {animalApplicationQuestions.map((question, idx) => (
                  <div
                    key={question.questionID}
                    style={{
                      display: "flex",
                      padding: "10px",
                      border: "1px solid black",
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <div
                      style={{
                        flex: 4,
                        borderRight: "1px solid black",
                      }}
                    >
                      <label style={{ fontWeight: "bold" }}>
                        {question.field_name}{" "}
                      </label>
                    </div>
                    <div style={{ flex: 8 }}>
                      {question.type === "Text" && (
                        <textarea
                          ref={(el) => (animalInputRef.current[idx] = el)}
                          // type={question.type === "Text" ? "text" : "number"}
                          defaultValue={question.response}
                          style={{
                            border: "none",
                            fontWeight: "bold",
                            width: "98%",
                            resize: "none",
                            marginLeft: "5px",
                            // marginRight: "5px",
                          }}
                          wrap="soft"
                        />
                      )}
                      {question.type === "Number" && (
                        <input
                          ref={(el) => (animalInputRef.current[idx] = el)}
                          type="number"
                          defaultValue={question.response}
                          style={{
                            border: "none",
                            // borderBottom: "1px solid black",
                            fontWeight: "bold",
                            marginLeft: "5px",
                          }}
                        />
                      )}
                      {question.type === "Yes/No" && (
                        <select
                          type="text"
                          ref={(el) => (animalInputRef.current[idx] = el)}
                          defaultValue={question.response}
                          style={{
                            border: "none",
                            // borderBottom: "1px solid black",
                            fontWeight: "bold",
                            marginLeft: "5px",
                          }}
                        >
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      {question.required && (
                        <label
                          style={{
                            color: question.highlight ? "red" : "black",
                            paddingLeft: "10px",
                          }}
                        >
                          (required)
                        </label>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            <br></br>
            <div style={{ textAlign: "center" }}>
              <Button>Submit</Button>
            </div>
            <br></br>
          </form>
        </div>
      </>
    );
  }
}

export default CompleteApplication;
