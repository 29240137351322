import { useRef, useContext, useCallback, useState, useEffect } from "react";
import "./NewMessageModal.css";
import AuthContext from "../store/auth-context";

import MiniLoadingSpinner from "./MiniLoadingSpinner";

import { db } from "../firebase";
import { addDoc, getDocs, collection } from "firebase/firestore";

import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

function NewMessageModal({
  animalID,
  applicantID,
  setOpenNewMessageModal,
  addMessageToList,
  nameOnEmail,
  emailAddress,
  pagename,
  senderUserID,
  senderUserName,
  messageType,
  rescueIDForMessages,
  initialMessage,
}) {
  const authCtx = useContext(AuthContext);

  const messagesCollectionRef = collection(db, "messages");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const messageInputRef = useRef();

  const [attachmentsArray, setAttachmentsArray] = useState([]);

  const [sendingMessage, setSendingMessage] = useState(false);

  useEffect(() => {
    // *****This is not Needed******.  It is just here to test pulling data from a firestore collection.
    const getMessages = async () => {
      try {
        const data = await getDocs(messagesCollectionRef);
        // console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch {
        alert(
          "Connection error!  Please log out and back in in order to send a message."
        );
      }
    };

    getMessages();
  }, [messagesCollectionRef]);

  // Create message in special table that is used to tell SendGrid to send an e-mail.
  const createFirestoreMessage = async (messageObject, messageId) => {
    await addDoc(messagesCollectionRef, {
      email: emailAddress,
      name: nameOnEmail,
      animalid: animalID,
      messageid: messageId,
      applicantid: applicantID,
      pagename: pagename,
    });

    // alert("Message has been sent!");

    setOpenNewMessageModal(false);
  };

  // This is used to send a new message.
  const sendMesssageHandler = useCallback(
    async (messageObject) => {
      // console.log("authCtx=", authCtx);
      // setPageShowing("RescueDashboard");
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://plenty-of-pooches-default-rtdb.firebaseio.com/messages.json?auth=" +
            authCtx.token,
          {
            method: "POST",
            body: JSON.stringify(messageObject),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while writing message to the database!"
          );
        }

        const data = await response.json();

        // console.log("Response data.name = ", data.name);

        addMessageToList(messageObject);

        createFirestoreMessage(messageObject, data.name);
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    [addMessageToList, authCtx.token, createFirestoreMessage]
  );

  function sendMessage() {
    setSendingMessage(true);
    // console.log("animalID=", animalID);
    // console.log("rescueID=", authCtx.rescueID);
    // console.log("applicantID=", applicantID);
    // console.log("rescueUserID=", authCtx.userID);
    // console.log("message=", messageInputRef.current.value);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    var hh = String(today.getHours()).padStart(2, "0");
    var mn = String(today.getMinutes()).padStart(2, "0");
    var ss = String(today.getSeconds()).padStart(2, "0");

    today = mm + "/" + dd + "/" + yyyy;
    const time = hh + ":" + mn + ":" + ss;

    const messageObject = {
      animalID: animalID,
      rescueID: rescueIDForMessages,
      applicantID: applicantID,
      senderUserID: senderUserID,
      senderUserName: senderUserName,
      type: messageType,
      date: today,
      time: time,
      read: "No",
      new: "Yes",
      message: messageInputRef.current.value,
      attachmentsArray: attachmentsArray,
      changedBy: authCtx.userID,
    };

    sendMesssageHandler(messageObject);
  }

  const uploadAttachment = (event) => {
    //
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_) => {
      setIsLoading(true);
      let files = Array.from(input.files);
      // console.log("files[0] = ", files[0]);
      // console.log("files[0].name =", files[0].name);

      // setImageUpload(files[0]);
      const attachment2Upload = files[0];

      if (attachment2Upload == null) return;

      const parts = files[0].name.split(".");
      // console.log("parts=", parts);
      const extension = parts[parts.length - 1];
      // console.log("extension=", extension);

      // ALAN
      const imageRef = ref(storage, `attachments/${v4()}.${extension}`);
      // console.log("imageRef=", imageRef);
      uploadBytes(imageRef, attachment2Upload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          // console.log("url=", url);
          setIsLoading(false);
          setAttachmentsArray((current) => [
            ...current,
            { name: files[0].name, url: url },
          ]);
        });
      });
    };
    input.click();
  };

  function deleteAttachment(index) {
    // console.log("Delete attachment with index", index);
    setAttachmentsArray((current) => {
      const before = [...current];
      // console.log("Before=", before);
      const after = [...before];
      after.splice(index, 1);
      // console.log("After=", after);
      return after;
    });
  }

  // console.log("NewMessageModal now open");
  // console.log("senderUserName", senderUserName);

  // console.log("initialMessage=", initialMessage);

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
      </>
    );
  } else if (sendingMessage === true) {
    return (
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="title">
            <h1>Sending Message....</h1>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="title">
            <h1>Send New Message to {nameOnEmail}</h1>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  {attachmentsArray.map((attachment, index) => (
                    <td
                      key={attachment.url}
                      style={{ border: "1px black solid" }}
                    >
                      <label>{attachment.name}</label>
                      <button onClick={deleteAttachment.bind(this, index)}>
                        X
                      </button>
                    </td>
                  ))}
                  <td>{isLoading && <MiniLoadingSpinner />}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="body">
            <textarea
              id="w3review"
              name="w3review"
              // rows="10"
              // cols="120"
              style={{ width: "90%", height: "85%", resize: "none" }}
              ref={messageInputRef}
              defaultValue={initialMessage}
            ></textarea>
          </div>
          <div className="footer">
            <button
              onClick={() => {
                setOpenNewMessageModal(false);
              }}
              id="cancelBtn"
            >
              Cancel
            </button>
            <button onClick={sendMessage}>SEND</button>
            <button onClick={uploadAttachment}>Attachment</button>
          </div>
        </div>
      </div>
    );
  }
}

export default NewMessageModal;
