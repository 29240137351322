import React, { useState } from "react";

// import firebase from "firebase";

const AuthContext = React.createContext({
  token: "",
  isLoggenIn: false,
  filterPref: "All Animals",
  authUserID: "",
  rescueID: "",
  userName: "",
  userIsAdmin: false,
  rescueOrgName: "",
  userID: "",
  refreshToken: "",
  loginHandler: (token) => {},
  logoutHandler: () => {},
  filterSetHandler: () => {},
  setAuthUserIDHandler: () => {},
  setRescueIDHandler: () => {},
  setUserIsAdminHandler: () => {},
  setUserNameHandler: () => {},
  setRescueOrgNameHandler: () => {},
  setUserIDHandler: () => {},
  setRefreshTokenHandler: () => {},
  onRefreshToken: () => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [authUserID, setAuthUserID] = useState(null);
  const [filterPref, setFilterProf] = useState("Available_Animals_Only");
  const [rescueID, setRescueID] = useState("");
  const [userIsAdmin, setUserIsAdmin] = useState("");
  const [userName, setUserName] = useState("");
  const [rescueOrgName, setRescueOrgName] = useState("");
  const [refreshToken, setRefreshToken] = useState("");

  // userID will be the key to the record in either the 'applicants' collection or the 'rescue_users' collection.
  const [userID, setUserID] = useState("");

  const userIsLoggenIn = !!token;

  let newRefreshToken = "";

  const parseCookie = (str) =>
    str
      .split(";")
      .map((v) => v.split("="))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {});

  const loginHandler = (token) => {
    setToken(token);
    // Also set a cookie so that the system knows this user is logged in if the page is refreshed or
    // a new tab is opened.
    let date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = "token=" + token + "; " + expires;
    localStorage.setItem("token", token);
  };

  const logoutHandler = () => {
    // console.log("LOGGING OUT USER");
    setToken(null);
    setRefreshToken(null);
    // Delete the cookie so that the website knows the user is logged out.
    document.cookie = "token=dummy; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = "rescueid=dummy; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie =
      "refreshtoken=dummy; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = "userid=dummy; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = "authuserid=dummy; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = "username=dummy; expires=Thu, 01 Jan 1970 00:00:01 GMT";

    let cookieString = document.cookie;
    // console.log("cookieString=", cookieString);

    localStorage.removeItem("token");
    // console.log("Deleting 1");
    localStorage.removeItem("rescueid");
    localStorage.removeItem("refreshtoken");
    localStorage.removeItem("userid");
    localStorage.removeItem("authuserid");
    localStorage.removeItem("username");
  };

  const setAuthUserIDHandler = (userID) => {
    setAuthUserID(userID);
    // Also set a cookie so that the system knows this user is logged in if the page is refreshed or
    // a new tab is opened.
    let date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = "authuserid=" + userID + "; " + expires;
    localStorage.setItem("authuserid", userID);
  };

  const filterSetHandler = (pref) => {
    setFilterProf(pref);
  };

  const setRescueIDHandler = (rescueID) => {
    setRescueID(rescueID);
    // Also set a cookie so that the system knows this user is logged in if the page is refreshed or
    // a new tab is opened.
    let date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = "rescueid=" + rescueID + "; " + expires;
    // console.log("Setting 'rescuid' to ", rescueID);
    localStorage.setItem("rescueid", rescueID);
  };

  const setUserIsAdminHandler = (x) => {
    setUserIsAdmin(x);
  };

  const setUserNameHandler = (name) => {
    setUserName(name);
    // Also set a cookie so that the system knows this user is logged in if the page is refreshed or
    // a new tab is opened.
    let date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = "username=" + name + "; " + expires;
    localStorage.setItem("username", name);
  };

  const setRescueOrgNameHandler = (name) => {
    setRescueOrgName(name);
  };

  const setUserIDHandler = (name) => {
    console.log("Setting authCtx.userID to ", name);
    setUserID(name);
    // Also set a cookie so that the system knows this user is logged in if the page is refreshed or
    // a new tab is opened.
    let date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = "userid=" + name + "; " + expires;
    localStorage.setItem("userid", name);
  };

  const setRefreshTokenHandler = (token) => {
    // console.log("Setting authCtx.refreshToken to ", token);
    setRefreshToken(token);
    newRefreshToken = token;
    // Also set a cookie so that the system knows this user is logged in if the page is refreshed or
    // a new tab is opened.
    let date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = "refreshtoken=" + token + "; " + expires;
    localStorage.setItem("refreshtoken", token);
  };

  function onRefreshToken() {
    let cookieString = document.cookie;

    console.log("cookieString=", cookieString);

    let parsedCookie = {};
    if (cookieString !== "") {
      parsedCookie = parseCookie(cookieString);
    }

    // AL if (parsedCookie.token === undefined || parseCookie.token === null) {
    if (localStorage.getItem("token") === null) {
      console.log("onRefreshToken - user is not logged in, so not running!");
    } else {
      console.log("running onRefreshToken - newRefreshToken=", newRefreshToken);
      fetch(
        "https://securetoken.googleapis.com/v1/token?key=AIzaSyA5WcjKGcpPV47I_UteWJLWidGBDVS4Q9Q",
        {
          method: "POST",
          body: JSON.stringify({
            grant_type: "refresh_token",
            refresh_token: newRefreshToken,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((data) => {
              let errorMessage = "Authentication Failes";
              if (data && data.error && data.error.message) {
                errorMessage = data.error.message;
              }
              throw new Error(errorMessage);
            });
          }
        })
        .then((data) => {
          console.log("data=", data);
          loginHandler(data.id_token);
          setRefreshTokenHandler(data.refresh_token);
          // Also update the cookie with the new tokens and new times (the only thing that should)
          // have changed is the 'token', but we need to update the time for all of them.
          // token
          let date = new Date();
          date.setTime(date.getTime() + 60 * 60 * 1000);
          let expires = "expires=" + date.toUTCString();
          document.cookie = "token=" + data.id_token + "; " + expires;
          localStorage.setItem("token", data.id_token);
          // refreshtoken
          date = new Date();
          date.setTime(date.getTime() + 60 * 60 * 1000);
          expires = "expires=" + date.toUTCString();
          document.cookie =
            "refreshtoken=" + data.refresh_token + "; " + expires;
          localStorage.setItem("refreshtoken", data.refresh_token);

          // For the following values, we want to use the value that is already in the cookie, but
          // we want to update the exiry date.
          let cookieString = document.cookie;

          console.log("cookieString=", cookieString);

          let parsedCookie = {};
          if (cookieString !== "") {
            parsedCookie = parseCookie(cookieString);
          }
          //
          // autheruserid
          date = new Date();
          date.setTime(date.getTime() + 60 * 60 * 1000);
          expires = "expires=" + date.toUTCString();
          document.cookie =
            "authuserid=" + parsedCookie.authuserid + "; " + expires;
          // userid
          date = new Date();
          date.setTime(date.getTime() + 60 * 60 * 1000);
          expires = "expires=" + date.toUTCString();
          document.cookie = "userid=" + parsedCookie.userid + "; " + expires;
          // rescueid
          if (parsedCookie.rescueid !== undefined) {
            date = new Date();
            date.setTime(date.getTime() + 60 * 60 * 1000);
            expires = "expires=" + date.toUTCString();
            document.cookie =
              "rescueid=" + parsedCookie.rescueid + "; " + expires;
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }

  const contextValue = {
    token: token,
    isLoggenIn: userIsLoggenIn,
    filterPref: filterPref,
    authUserID: authUserID,
    rescueID: rescueID,
    userName: userName,
    userIsAdmin: userIsAdmin,
    rescueOrgName: rescueOrgName,
    userID: userID,
    refreshToken: refreshToken,
    loginHandler: loginHandler,
    logoutHandler: logoutHandler,
    filterSetHandler: filterSetHandler,
    setAuthUserIDHandler: setAuthUserIDHandler,
    setRescueIDHandler: setRescueIDHandler,
    setUserIsAdminHandler: setUserIsAdminHandler,
    setUserNameHandler: setUserNameHandler,
    setRescueOrgNameHandler: setRescueOrgNameHandler,
    setUserIDHandler: setUserIDHandler,
    setRefreshTokenHandler: setRefreshTokenHandler,
    onRefreshToken: onRefreshToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
