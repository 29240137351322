import { useParams, useHistory } from "react-router-dom";
import { useState, useRef, useContext } from "react";
import LoadingSpinner from "./LoadingSpinner";

import AuthContext from "../store/auth-context";
import classes from "./ChangePassword.module.css";

function ChangePassword() {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const passwordInputRef = useRef();
  const reenterPasswordInputRef = useRef();

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredPassword = passwordInputRef.current.value;
    const enteredReenterPassword = reenterPasswordInputRef.current.value;

    if (enteredPassword !== enteredReenterPassword) {
      alert("Passwords Do Not Match");
    } else {
      setIsLoading(true);

      let url;
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyA5WcjKGcpPV47I_UteWJLWidGBDVS4Q9Q";

      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          idToken: authCtx.token,
          password: enteredPassword,
          returnSecureToken: true,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setIsLoading(false);
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((data) => {
              let errorMessage = "Changing Password Failes";
              if (data && data.error && data.error.message) {
                errorMessage = data.error.message;
              }
              throw new Error(errorMessage);
            });
          }
        })
        .then((data) => {
          // console.log("data=", data);
          // Update the token with the new one
          authCtx.loginHandler(data.idToken);

          alert("Password Successfully Changed!");

          // Now return to the RescueDashboard screen
          if (params.userType === "rescue") {
            history.replace("/rescue-dashboard/" + authCtx.userID);
          } else {
            history.replace("/applicant-dashboard/" + authCtx.userID);
          }
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  };

  function onCancelChangePassword() {
    // Now return to the RescueDashboard screen
    if (params.userType === "rescue") {
      history.replace("/rescue-dashboard/" + authCtx.userID);
    } else {
      history.replace("/applicant-dashboard/" + authCtx.userID);
    }
  }

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <button onClick={onLogout}>Login Again</button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <section className={classes.auth}>
        <h1>Change Password</h1>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              id="password"
              required
              ref={passwordInputRef}
            />
          </div>
          <div className={classes.control}>
            <label htmlFor="reenterpassword">Re-enter Password</label>
            <input
              type="password"
              id="reenterpassword"
              required
              ref={reenterPasswordInputRef}
            />
          </div>
          <div className={classes.actions}>
            {!isLoading && <button>Update Password</button>}
            {isLoading && <p style={{ color: "white" }}>Sending Data...</p>}
            <br></br>
            <button type="button" onClick={onCancelChangePassword}>
              Cancel
            </button>
          </div>
        </form>
      </section>
    );
  }
}

export default ChangePassword;
