import AuthForm from "./Components/AuthForm";
import RescueDashboard from "./Components/RescueDashboard";
import AddAnimal from "./Components/AddAnimal";
import AnimalDetail from "./Components/AnimalDetail";
import ApplicantDetails from "./Components/ApplicantDetails";
import AddUser from "./Components/AddUser";
import RescueUsers from "./Components/RescueUsers";
import ApplicantsList from "./Components/ApplicantsList";
import ChangePassword from "./Components/ChangePassword";
import ApplicantDashboard from "./Components/ApplicantDashboard";
import AppAnimalDetail from "./Components/AppAnimalDetail";
import ApplyToAdopt from "./Components/ApplyToAdopt";
import CompleteApplication from "./Components/CompleteApplication";
import ViewApplication from "./Components/ViewApplication";
import UpdateRescueInfo from "./Components/UpdateRescueInfo";
import ApplicantFolder from "./Components/ApplicantFolder";

import { Route, Switch } from "react-router-dom";

import "./App.css";
import { useState, useContext, useEffect } from "react";
import AuthContext from "./store/auth-context";

function App() {
  const [pageShowing, setPageShowing] = useState("AuthForm");
  const [currentAnimalShowing, setCurrentAnimalShowing] = useState("");
  const [currentApplicantShowing, setCurrentApplicantShowing] = useState("");

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    // console.log("Kicking off tokenrefresh job");
    // This will set up a scheduled job to automatically get a new token every hour (since the token provided
    // by Firebase expired after one hour.)
    const incrementTimer = setInterval(authCtx.onRefreshToken, 3570000);
    // const incrementTimer = setInterval(authCtx.onRefreshToken, 15000);
  }, []);

  // console.log(
  //   "Rescue id from local storage=",
  //   localStorage.getItem("rescueid")
  // );

  return (
    <>
      <Switch>
        <Route path="/login/:loginType/:secondParam/:animalID">
          <AuthForm />
        </Route>
        <Route path="/apply-to-adopt/:animalID">
          <ApplyToAdopt />
        </Route>
        <Route path="/applicants-list/:rescueID">
          <ApplicantsList />
        </Route>
        <Route path="/complete-application/:rescueID/:animalID">
          <CompleteApplication />
        </Route>
        <Route path="/applicant-dashboard/:applicantID">
          <ApplicantDashboard />
        </Route>
        <Route path="/app-animal-detail/:applicantID/:animalID/:special">
          <AppAnimalDetail />
        </Route>
        <Route path="/rescue-dashboard/:rescueUserID">
          <RescueDashboard />
        </Route>
        <Route path="/animal_details/:animalID">
          <AnimalDetail />
        </Route>
        <Route path="/applicant-details/:applicantID/:animalID/:special">
          <ApplicantDetails />
        </Route>
        <Route path="/change-password/:userType">
          <ChangePassword />
        </Route>
        <Route path="/rescue-users/:rescueID">
          <RescueUsers />
        </Route>
        <Route path="/add-user">
          <AddUser />
        </Route>
        <Route path="/add-animal/:animalID">
          <AddAnimal />
        </Route>
        <Route path="/view-application/:rescueID/:animalID/:applicantID">
          <ViewApplication />
        </Route>
        <Route path="/update-rescue-info/:rescueID">
          <UpdateRescueInfo />
        </Route>
        <Route path="/applicant-folder/:applicantID">
          <ApplicantFolder />
        </Route>
        <Route path="">
          <AuthForm />
        </Route>
      </Switch>
    </>
  );
}

export default App;
