import { useParams, useHistory } from "react-router-dom";
import { useCallback, useEffect, useState, useContext, useRef } from "react";
import LoadingSpinner from "./LoadingSpinner";
import AuthContext from "../store/auth-context";
import Button from "../UI/Button";

function ApplicantsList(props) {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [applicants, setApplicants] = useState([]);

  let tmpApplicants = [];

  // This is where we get the list of all applicants who have applied to adopt this animal.
  const fetchApplicationsHandler = useCallback(async () => {
    // console.log("Fetching List Of Applicants...");

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applications.json?orderBy="rescueID"&equalTo="' +
          params.rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving list of applications!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      tmpApplicants = [];

      for (const key in data) {
        // console.log(
        //   "data[key].animalID.substring(0, 3)=",
        //   data[key].animalID.substring(0, 3)
        // );

        const found = tmpApplicants.findIndex(
          (e) => e.applicantID === data[key].applicantID
        );
        if (found !== -1) {
          // console.log("Duplicate found");
          if (data[key].animalID.substring(0, 3) !== "ReS") {
            tmpApplicants[found].noApplications =
              tmpApplicants[found].noApplications + 1;
          }
        } else {
          tmpApplicants.push({
            applicantID: data[key].applicantID,
            applicantName: "",
            noApplications:
              data[key].animalID.substring(0, 3) === "ReS" ? 0 : 1,
            applicantLastName: "",
          });
        }
      }

      tmpApplicants.forEach((e, index) => {
        fetchApplicantDetailsHandler(index, e.applicantID);
      });

      // console.log("tmpApplicants=", tmpApplicants);

      setApplicants(tmpApplicants);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // This is where we get the details of the applicant (like their name)
  const fetchApplicantDetailsHandler = useCallback(
    async (index, applicantID) => {
      // console.log(`Fetching Details Of Applicant ${applicantID}...`);

      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
            applicantID +
            '"&auth=' +
            authCtx.token
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while retrieving applicant details!"
          );
        }

        const data = await response.json();

        let applicantsName = "Unknown";
        for (const key in data) {
          // console.log(`>> Name for position ${index} =`, data[key].name);
          applicantsName = data[key].name;
        }

        setApplicants((prev) => {
          const loadedApplicants = [];
          prev.forEach((e1, idx) => {
            // console.log("e1.key=", e1.key);
            let name = e1.applicantName;
            if (idx === index) {
              name = applicantsName;
            }

            loadedApplicants.push({
              applicantID: e1.applicantID,
              applicantName: name,
              noApplications: e1.noApplications,
              applicantLastName: name.split(" ")[1],
            });
          });
          return loadedApplicants;
        });

        // for (const key in data) {
        //   loadedApplications.push({
        //     key: key,
        //     applicantID: data[key].applicantID,
        //   });
        // }

        // console.log("loadedApplications=", loadedApplications);

        // setApplications(loadedApplications);
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    // console.log("Running useEffect");
    fetchApplicationsHandler(params.rescueID);
  }, [fetchApplicationsHandler]);

  function backToDashboard() {
    history.push("/rescue-dashboard/" + authCtx.userID);
  }

  function compare(a, b) {
    if (a.applicantLastName < b.applicantLastName) {
      return -1;
    }
    if (a.applicantLastName > b.applicantLastName) {
      return 1;
    }
    return 0;
  }

  const sortedApplicants = [...applicants];
  sortedApplicants.sort(compare);

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  function onApplicantDetail(applicantID) {
    //
    history.push("/applicant-folder/" + applicantID);
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button onClick={backToDashboard}>
                            Back to Dashboard
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "7vh",
                    fontSize: "4vh",
                    fontWeight: "bold",
                  }}
                >
                  List of Applicants
                </td>
                <td style={{ width: "20%" }}></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br></br>
        <table style={{ width: "calc(100% - 1em)" }}>
          <tbody>
            <tr
              key="header"
              style={{ backgroundColor: "#38015c", color: "white" }}
            >
              {/* <td style={{ border: "1px solid black" }}>Animal ID</td> */}
              <td style={{ border: "1px solid black", width: "40%" }}>
                Applicant Name
              </td>
              <td style={{ border: "1px solid black", width: "60%" }}>
                Number of Animals Applicant Has Applied To Adopt
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              {sortedApplicants.map((applicant) => (
                <tr key={applicant.applicantID}>
                  <td style={{ border: "1px solid black", width: "40%" }}>
                    <label
                      onClick={onApplicantDetail.bind(
                        this,
                        applicant.applicantID
                      )}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {applicant.applicantName}
                    </label>
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      width: "60%",
                    }}
                  >
                    {applicant.noApplications === 0
                      ? ""
                      : applicant.noApplications}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default ApplicantsList;
