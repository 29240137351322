import { useParams, useHistory } from "react-router-dom";
import { useState, useRef, useContext, useCallback } from "react";
import LoadingSpinner from "./LoadingSpinner";

import AuthContext from "../store/auth-context";
import classes from "./AddUser.module.css";

const AddUser = (props) => {
  // console.log("Executing AddUser component");
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [error, setError] = useState(null);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const reenterPasswordInputRef = useRef();
  const userNameInputRef = useRef();
  const isAdminInputRef = useRef();

  const isAdmin = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault();

    isAdmin.current = isAdminInputRef.current.checked;

    console.log("1. isAdmin.current=", isAdmin.current);

    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    const enteredReenterPassword = reenterPasswordInputRef.current.value;
    const enteredUserName = userNameInputRef.current.value;

    if (enteredPassword !== enteredReenterPassword) {
      alert("Passwords Do Not Match");
    } else {
      setIsLoading(true);

      let url;
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyA5WcjKGcpPV47I_UteWJLWidGBDVS4Q9Q";
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          email: enteredEmail,
          password: enteredPassword,
          returnSecureToken: true,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setIsLoading(false);
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((data) => {
              let errorMessage = "Authentication Failes";
              if (data && data.error && data.error.message) {
                errorMessage = data.error.message;
              }
              throw new Error(errorMessage);
            });
          }
        })
        .then((data) => {
          // alert("User successfully added");

          // console.log("enteredUserName=", enteredUserName);

          console.log("2. isAdmin.current=", isAdmin.current);

          let user = {
            isadmin: isAdmin.current,
            name: enteredUserName,
            rescue_id: authCtx.rescueID,
            active: true,
            changedBy: authCtx.userID,
          };

          console.log("user data=", user);

          addUserHandler(data.localId, user);
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  const addUserHandler = useCallback(async (authUserID, user) => {
    // console.log("Setting up user in 'rescue_user' table... user=", user);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://plenty-of-pooches-default-rtdb.firebaseio.com/rescue_users/${authUserID}.json?auth=` +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing animal to database!"
        );
      }

      const data = await response.json();

      // props.closeAddAnimalPage();
      alert("User successfully added to database");

      cancelAddUserHandler();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  function cancelAddUserHandler() {
    // console.log("authCtx.rescueID=", authCtx.rescueID);
    history.replace("/rescue-users/" + authCtx.rescueID);
  }

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <button onClick={onLogout}>Login Again</button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <section className={classes.auth}>
        <h1>Add User</h1>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor="username">User's Name</label>
            <input id="username" required ref={userNameInputRef} />
          </div>
          <div className={classes.control}>
            <label htmlFor="isadmin">Is Administrator?</label>
            <input type="checkbox" id="isadmin" ref={isAdminInputRef} />
          </div>
          <div className={classes.control}>
            <label htmlFor="email">User's Email</label>
            <input type="email" id="email" required ref={emailInputRef} />
          </div>
          <div className={classes.control}>
            <label htmlFor="password">User's Password</label>
            <input
              type="password"
              id="password"
              required
              ref={passwordInputRef}
            />
          </div>
          <div className={classes.control}>
            <label htmlFor="reenterpassword">Re-enter Password</label>
            <input
              type="password"
              id="reenterpassword"
              required
              ref={reenterPasswordInputRef}
            />
          </div>
          <div className={classes.actions}>
            {!isLoading && <button>"Create Account"</button>}
            {isLoading && <p style={{ color: "white" }}>Sending Data...</p>}
          </div>
          <div className={classes.actions}>
            <button type="button" onClick={cancelAddUserHandler}>
              Cancel
            </button>
          </div>
        </form>
      </section>
    );
  }
};

export default AddUser;
