import { useRef, useContext, useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import AuthContext from "../store/auth-context";
import Button from "../UI/Button";
import LoadingSpinner from "./LoadingSpinner";

function ApplicantFolder() {
  const authCtx = useContext(AuthContext);

  const params = useParams();

  const history = useHistory();

  const [appNotes, setAppNotes] = useState("Loading...");
  const appNoteID = useRef("none");

  const [appPubNotes, setAppPubNotes] = useState("Loading...");
  const appPubNoteID = useRef("none");

  const [publicNotes, setPublicNotes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [applicant, setApplicant] = useState("");

  function onAppNotesChangeHandler(event) {
    // console.log(event.target.value);
    setAppNotes(event.target.value);
  }

  function onPubAppNotesChangeHandler(event) {
    setAppPubNotes(event.target.value);
  }

  // This is where we get the details of the applicant (like their name)
  const fetchRescueDetailsHandler = useCallback(async (index, rescueID) => {
    // console.log(`Fetching Details Of Applicant ${applicantID}...`);

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/rescues.json?orderBy="$key"&equalTo="' +
          rescueID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving rescue details!"
        );
      }

      const data = await response.json();

      let rescuesName = "Unknown";
      for (const key in data) {
        // console.log(`>> Name for position ${index} =`, data[key].name);
        rescuesName = data[key].name;
      }

      setPublicNotes((prev) => {
        const loadedNotes = [];
        prev.forEach((e1, idx) => {
          // console.log("e1.key=", e1.key);
          let name = e1.rescueName;
          if (idx === index) {
            name = rescuesName;
          }

          loadedNotes.push({
            key: e1.key,
            rescueID: e1.rescueID,
            note: e1.note,
            rescueName: name,
          });
        });
        return loadedNotes;
      });
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch the details of this applicant
  const fetchApplicantDetailsHandler = useCallback(async () => {
    // console.log("Fetching List Of Animals...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicants.json?orderBy="$key"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant details!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      let applicantTemp = {};

      for (const key in data) {
        applicantTemp = {
          key: key,
          address: data[key].address,
          name: data[key].name,
          phone: data[key].phone,
          email: data[key].email,
        };
      }

      // console.log("applicantTemp = ", applicantTemp);

      setApplicant(applicantTemp);

      // Get the applicant note
      fetchApplicantNote();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Fetch the existing note for this applicant
  const fetchApplicantNote = useCallback(async () => {
    // console.log("Fetching existing note for this applicant...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicant_notes.json?orderBy="applicantID"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant note!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);

      setAppNotes("");
      for (const key in data) {
        if (data[key].rescueID === authCtx.rescueID) {
          // console.log("Setting note to ", data[key].note);
          setAppNotes(data[key].note);
          appNoteID.current = key;
        }
      }

      fetchPublicAppNotes();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to update applicant message
  const updateAppmessage = useCallback(
    async (noteID, note, publicNoteID, publicNote) => {
      // console.log(`Setting app note with ID ${noteID} to ${note}`);
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://plenty-of-pooches-default-rtdb.firebaseio.com/applicant_notes/" +
            noteID +
            ".json?" +
            '"&auth=' +
            authCtx.token,
          {
            method: "PATCH",
            body: JSON.stringify({
              note: note,
              changedBy: authCtx.userID,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while writing applicant note to databse!"
          );
        }

        const data = await response.json();

        updatePublicNotesHandler(publicNoteID, publicNote);
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    []
  );

  // Function to add applicant message
  const addAppmessage = useCallback(
    async (noteID, note, publicNoteID, publicNote) => {
      // console.log(`Setting app note with ID ${noteID} to ${note}`);
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://plenty-of-pooches-default-rtdb.firebaseio.com/applicant_notes.json?" +
            '"&auth=' +
            authCtx.token,
          {
            method: "POST",
            body: JSON.stringify({
              applicantID: params.applicantID,
              rescueID: authCtx.rescueID,
              note: note,
              changedBy: authCtx.userID,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while writing applicant note to databse!"
          );
        }

        const data = await response.json();

        updatePublicNotesHandler(publicNoteID, publicNote);
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    []
  );

  // Fetch the existing public notes for this applicant
  const fetchPublicAppNotes = useCallback(async () => {
    // console.log("Fetching existing note for this applicant...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://plenty-of-pooches-default-rtdb.firebaseio.com/applicant_notes_public.json?orderBy="applicantID"&equalTo="' +
          params.applicantID +
          '"&auth=' +
          authCtx.token
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while retrieving applicant note!"
        );
      }

      const data = await response.json();

      // console.log(">> data=", data);
      const tempPublicNotes = [];

      setAppPubNotes("");
      for (const key in data) {
        if (data[key].rescueID === authCtx.rescueID) {
          // console.log("Setting note to ", data[key].note);
          // This is the note that is for this rescue.  Therefore, the user can edit it.
          setAppPubNotes(data[key].note);
          appPubNoteID.current = key;
        } else {
          // This is a note from another rescue.  Therefore, it is read-only.
          tempPublicNotes.push({
            key: key,
            rescueID: data[key].rescueID,
            note: data[key].note,
            rescueName: "",
          });
        }
      }

      setPublicNotes(tempPublicNotes);

      // Now, for each rescue, get the rescue's name
      tempPublicNotes.forEach((e, index) => {
        // console.log(`Fetching rescue details for `, e);
        fetchRescueDetailsHandler(index, e.rescueID);
        // loadedApplications[index].applicantName = fetchApplicantDetailsHandler(
        //   e.applicantID
        // );
      });
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to update public applicant message
  const updatePublicAppmessage = useCallback(async (noteID, note) => {
    // console.log(`Setting public app note with ID ${noteID} to ${note}`);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://plenty-of-pooches-default-rtdb.firebaseio.com/applicant_notes_public/" +
          noteID +
          ".json?" +
          '"&auth=' +
          authCtx.token,
        {
          method: "PATCH",
          body: JSON.stringify({
            note: note,
            changedBy: authCtx.userID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Something went wrong while writing public applicant note to databse!"
        );
      }

      const data = await response.json();

      closeAppNotesPage();
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  // Function to add public applicant message
  const addPublicAppmessage = useCallback(
    async (noteID, note, publicNoteID, publicNote) => {
      // console.log(`Setting app note with ID ${noteID} to ${note}`);
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://plenty-of-pooches-default-rtdb.firebaseio.com/applicant_notes_public.json?" +
            '"&auth=' +
            authCtx.token,
          {
            method: "POST",
            body: JSON.stringify({
              applicantID: params.applicantID,
              rescueID: authCtx.rescueID,
              note: note,
              changedBy: authCtx.userID,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            "Something went wrong while writing applicant note to databse!"
          );
        }

        const data = await response.json();

        closeAppNotesPage();
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    fetchApplicantDetailsHandler();
  }, []);

  function onLogout() {
    authCtx.logoutHandler();
    history.replace("/login");
  }

  function updateNotesHandler() {
    if (appNoteID.current === "none") {
      // console.log("CREATING NEW APPLICANT NOTE");
      addAppmessage(
        appNoteID.current,
        appNotes,
        appPubNoteID.current,
        appPubNotes
      );
    } else {
      // console.log(
      //   "UPDATING EXISTING APPLICANT NOTE WITH ID ",
      //   appNoteID.current
      // );
      updateAppmessage(
        appNoteID.current,
        appNotes,
        appPubNoteID.current,
        appPubNotes
      );
    }
  }

  function updatePublicNotesHandler(publicNoteID, publicNote) {
    if (publicNoteID === "none") {
      console.log("CREATING NEW PUBLIC APPLICANT NOTE");
      addPublicAppmessage(publicNoteID, publicNote);
    } else {
      // console.log(
      //   "UPDATING EXISTING PUBLIC APPLICANT NOTE WITH ID ",
      //   publicNoteID
      // );
      updatePublicAppmessage(publicNoteID, publicNote);
    }
  }

  function closeAppNotesPage() {
    window.history.go(-1);
  }

  function onViewApplications() {
    history.push(
      "/view-application/" +
        authCtx.rescueID +
        "/" +
        "ALL" +
        "/" +
        params.applicantID
    );
  }

  if (error !== null) {
    return (
      <>
        <h1>{error}</h1>
        <Button onClick={onLogout}>Login Again</Button>
      </>
    );
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div
          style={{
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#9f5ccc",
            height: "20vh",
            borderBottom: "2px solid black",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button onClick={closeAppNotesPage}>
                            Close Without Saving
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button onClick={updateNotesHandler}>
                            Save Changes
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ width: "60%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "7vh",
                      fontSize: "4vh",
                      fontWeight: "bold",
                    }}
                  >
                    <label>Details for Applicant </label>
                    <label style={{ textDecoration: "underline" }}>
                      {applicant.name}
                    </label>
                  </div>
                </td>
                <td style={{ width: "20%" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button onClick={onViewApplications}>
                            View Application
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            height: "79vh",
            overflowY: "scroll",
            border: "1px solid black",
          }}
        >
          <h2 style={{ textDecoration: "underline" }}>
            Note that only people from your rescue can see:
          </h2>
          <textarea
            id="appnotes"
            name="appnotes"
            style={{ width: "90%", height: "11em", resize: "none" }}
            value={appNotes}
            onChange={onAppNotesChangeHandler}
          ></textarea>
          <h2 style={{ textDecoration: "underline" }}>
            Note that people from other rescues can see:
          </h2>
          <textarea
            id="publicnotes"
            name="publicnotes"
            style={{ width: "90%", height: "11em", resize: "none" }}
            value={appPubNotes}
            onChange={onPubAppNotesChangeHandler}
          ></textarea>
          <h2 style={{ textDecoration: "underline" }}>
            Notes from other rescues:
          </h2>
          {publicNotes.map((messageObj, indx) => (
            <div key={messageObj.key} style={{ width: "90%", border: "none" }}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr style={{ width: "100%" }}>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <h3>Note from '{messageObj.rescueName}'</h3>
                    </td>
                  </tr>
                  <tr style={{ width: "100%" }}>
                    <textarea
                      id="appnotesa"
                      name="appnotesa"
                      style={{ width: "100%", height: "11em", resize: "none" }}
                      value={messageObj.note}
                      onChange={onAppNotesChangeHandler}
                    ></textarea>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default ApplicantFolder;
